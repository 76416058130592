import React from "react";
import {AiOutlineLoading} from "react-icons/ai";

export default class International extends React.Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className={'text-center'}>
                <AiOutlineLoading size={'80'}/>
            </div>
        );
    }
}