import React from "react";
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import Navigation from "./Components/Navigation/Navigation";
import Contact from "./Pages/Contact";
import History from "./Pages/About/History";
import NationalBoard from "./Pages/About/NationalBoard";
import RollOfHonour from "./Pages/About/RollOfHonour";
import ActiveCities from "./Pages/About/ActiveCities";
import International from "./Pages/About/International";
import Yap from "./Pages/Programs/Yap";
import Yip from "./Pages/Programs/Yip";
import Syj from "./Pages/Projects/syj";
import Ftde from "./Pages/Projects/ftde";
import OtherProjects from "./Pages/Projects/OtherProjects";
import SelfieCommunication from "./Pages/Communication/SelfieCommunication";
import FellowshipCommunication from "./Pages/Communication/FellowshipCommunication";
import HappeningsCommunication from "./Pages/Communication/HappeningsCommunication";
import Home from "./Pages/Home";
import PageNotFound from "./Pages/PageNotFound";
import About from "./Pages/About/About";
import PrivacyPolicy from "./Pages/Legalese/PrivacyPolicy";
import TermsConditions from "./Pages/Legalese/TermsConditions";
import CancellationRefund from "./Pages/Legalese/CancellationRefund";
import Footer from "./Components/Footer";
import OtherCommunication from "./Pages/Communication/OtherCommunication";
import Events from "./Pages/Events";
import NewMember from "./Pages/Extension/NewMember";
import ClubCharter from "./Pages/Extension/ClubCharter";
import MemberTransfer from "./Pages/Extension/MemberTransfer";
import Donate from "./Pages/Donate";

export default function App() {
    return (
        <Router>
            <Navigation/>
            <Switch>
                <Route path="/about">
                    <AboutSection/>
                </Route>
                <Route path="/programs/yap">
                    <Yap/>
                </Route>
                <Route path="/programs/yip">
                    <Yip/>
                </Route>
                <Route path="/projects/syj">
                    <Syj/>
                </Route>
                <Route path="/projects/ftde">
                    <Ftde/>
                </Route>
                <Route path="/projects/others">
                    <OtherProjects/>
                </Route>
                <Route path="/communication/selfie">
                    <SelfieCommunication/>
                </Route>
                <Route path="/communication/fellowship">
                    <FellowshipCommunication/>
                </Route>
                <Route path="/communication/happenings">
                    <HappeningsCommunication/>
                </Route>
                <Route path="/communication/others">
                    <OtherCommunication/>
                </Route>
                {/*<Route path="/archives/selfie">*/}
                {/*    <SelfieArchives/>*/}
                {/*</Route>*/}
                {/*<Route path="/archives/fellowship">*/}
                {/*    <FellowshipArchives/>*/}
                {/*</Route>*/}
                {/*<Route path="/archives/happenings">*/}
                {/*    <HappeningsArchives/>*/}
                {/*</Route>*/}
                {/*<Route path="/archives/others">*/}
                {/*    <OtherArchives/>*/}
                {/*</Route>*/}
                <Route path="/events">
                    <Events/>
                </Route>
                <Route path="/contact">
                    <Contact/>
                </Route>
                <Route path="/privacy">
                    <PrivacyPolicy/>
                </Route>
                <Route path="/terms-conditions">
                    <TermsConditions/>
                </Route>
                <Route path="/refunds">
                    <CancellationRefund/>
                </Route>
                <Route path="/donate">
                    <Donate />
                </Route>
                {/*<Route path="/extension/new-member">*/}
                {/*    <NewMember />*/}
                {/*</Route>*/}
                {/*<Route path="/extension/club-charter">*/}
                {/*    <ClubCharter />*/}
                {/*</Route>*/}
                {/*<Route path="/extension/member-transfer">*/}
                {/*    <MemberTransfer />*/}
                {/*</Route>*/}
                <Route path="/" exact>
                    <Home/>
                </Route>
                <Route path="/">
                    <PageNotFound/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    );
}

function AboutSection() {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/history`}>
                <History/>
            </Route>
            <Route path={`${match.path}/national-board`}>
                <NationalBoard/>
            </Route>
            <Route path={`${match.path}/roll-of-honour`}>
                <RollOfHonour/>
            </Route>
            <Route path={`${match.path}/active-cities`}>
                <ActiveCities/>
            </Route>
            <Route path={`${match.path}/international`}>
                <International/>
            </Route>
            <Route path={match.path}>
                <About/>
            </Route>

            {/*<Route path={`${match.path}/:topicId`}>*/}
            {/*    <Topic/>*/}
            {/*</Route>*/}
            {/*<Route path={match.path}>*/}
            {/*    <h3>Please select a topic.</h3>*/}
            {/*</Route>*/}
        </Switch>
    );
}

// function Topic() {
//     let {topicId} = useParams();
//     return <h3>Requested topic ID: {topicId}</h3>;
// }
