import React from "react";
import PublicationViewer from "../../Components/PublicationViewer";
import Seo from "../../Components/Seo";

export default class SelfieCommunication extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Selfie Magazine | 41 India'}
                     description={'View the current and past editions of the Selfie Magazine of 41 India'}
                     slug={'/communication/selfie'} />
                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/communication/header.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
                <PublicationViewer obj={obj}/>
            </div>
        );
    }
}

let obj = {
    header: 'Selfie',
    data: [
        {
            title: '',
            issues: [
                {
                    title: 'Issue 3-4 2023-2024',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/3-4-2024.pdf',
                    image: ''
                },
                {
                    title: 'Issue 2 2023-2024',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/2-2024.pdf',
                    image: ''
                },
                {
                    title: 'Issue 1 2023-2024',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/1-2024.pdf',
                    image: ''
                },
                {
                    title: 'February 2023',
                    link: 'https://online.fliphtml5.com/lgctu/zeyu/',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/February 2023.jpeg'
                },
                {
                    title: 'January 2022',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/selfi_January2022.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/January 2022.jpg'
                },
                {
                    title: 'December 2021',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfi_December2021.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/December 2021.jpg'
                },
                {
                    title: 'November 2021',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfi_November2021.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/November 2021.jpg'
                },
                {
                    title: 'November 2020',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/41andBeyond_November_2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/November 2020.jpg'
                },
                {
                    title: 'August 2020',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie_August_2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/August 2020.jpg'
                },
                {
                    title: 'March 2020',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie_March_2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/March 2020.jpg'
                },
                {
                    title: 'February 2020',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie_February_2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/February 2020.jpg'
                },
                {
                    title: 'January 2020',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/january2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/January 2020.jpg'
                },
                {
                    title: 'December 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie_December_2019.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/December 2019.jpg'
                },
                {
                    title: 'November 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/november2019.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/November 2019.jpg'
                },
                {
                    title: 'October 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/October2019.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/October 2019.jpg'
                },
                {
                    title: 'September 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie-12September2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/September 2019.jpg'
                },
                {
                    title: 'August 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 11 Aug 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/August 2019.jpg'
                },
                {
                    title: 'July 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 10 July 2018-19 New.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/July 2019.jpg'
                },
                {
                    title: 'June 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 09 June 2019 new.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/June 2019.jpg'
                },
                {
                    title: 'May 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 08 May 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/May 2019.jpg'
                },
                {
                    title: 'April 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 07 April 2019.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/April 2019.jpg'
                },
                {
                    title: 'March 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie 06 March 2018-19.pdf ',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/March 2019.jpg'
                },
                {
                    title: 'February 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie February 05 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/February 2019.jpg'
                },
                {
                    title: 'January 2019',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie January 04 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/January 2019.jpg'
                },
                {
                    title: 'December 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie December 03 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/December 2018.jpg'
                },
                {
                    title: 'November 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie November 02 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/November 2018.jpg'
                },
                {
                    title: 'October 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/Selfie October 01 2018-19.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/October 2018.jpg'
                },
                {
                    title: 'September 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/17 Selfie September 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/September 2018.jpg'
                },
                {
                    title: 'August 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/16 Selfie August 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/August 2018.jpg'
                },
                {
                    title: 'July 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/15 Selfie - July 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/July 2018.jpg'
                },
                {
                    title: 'June 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/14 Selfie June 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/June 2018.jpg'
                },
                {
                    title: 'May 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/13 Selfie May 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/May 2018.jpg'
                },
                {
                    title: 'April 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/12 Selfie April 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/April 2018.jpg'
                },
                {
                    title: 'March 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/11 Selfie March 2018.pdf ',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/March 2018.jpg'
                },
                {
                    title: 'February 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/10 Selfie February 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/February 2018.jpg'
                },
                {
                    title: 'January 2018',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/9 Selfie January 2018.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/January 2018.jpg'
                },
                {
                    title: 'December 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/8 Selfie December 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/December 2017.jpg'
                },
                {
                    title: 'November 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/ 7 Selfie November 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/Noveber 2017.jpg'
                },
                {
                    title: 'October 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/6 Selfie October 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/October 2017.jpg'
                },
                {
                    title: 'September 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/5 Selfie September 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/September 2017.jpg'
                },
                {
                    title: 'August 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/4 Selfie August 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/August 2017.jpg'
                },
                {
                    title: 'July 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/3 Selfie July 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/July 2017.jpg'
                },
                {
                    title: 'June 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/2 Selfie June 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/June 2017.jpg'
                },
                {
                    title: 'April - May 2017',
                    link: 'https://assets.41clubsofindia.org/communication/selfie/1 Selfie April - May 2017.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/selfie/april may 2017.jpg'
                },
            ]
        }
    ]
}

