import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className={'bg-black footer'}>
                <Container style={{paddingTop: '1%', paddingBottom: '1%'}}>
                    <Row>
                        <Col>
                            <img src='https://assets.41clubsofindia.org/logo.jpg' width={'50%'}/>
                        </Col>
                        <Col>
                            <p>
                                <Link to="/privacy" style={{textDecoration: 'none', color: 'white'}}>Privacy
                                    Policy</Link><br/>
                                <Link to="/terms-conditions" style={{textDecoration: 'none', color: 'white'}}>Terms and
                                    Conditions</Link><br/>
                                <Link to="/refunds" style={{textDecoration: 'none', color: 'white'}}>Refunds</Link>
                            </p>
                        </Col>
                        <Col>
                            <p style={{textDecoration: 'none', color: 'white'}}>
                                <strong>41 India</strong><br/>
                                Round Table House, 3rd Floor<br/>
                                80, Nungambakkam High Road<br/>
                                Chennai – 600 034<br/>
                                044 28274539<br/>
                                (+91) 7358673241<br/>
                                office41india@gmail.com
                            </p>
                        </Col>
                        <Col>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5592836786645!2d80.2417422154913!3d13.063701016383616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526668ade2eb13%3A0x2e4cc3a1a95fbff3!2sAssociation%20of%2041%20Clubs%20of%20India!5e0!3m2!1sen!2sin!4v1667567757527!5m2!1sen!2sin"
                                width="100%" height="200" style={{border: 0}} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"/>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}