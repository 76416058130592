import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";
import ProjectsCarousel from "./ProjectsCarousel";
import SimpleCarousel from "../../Components/SimpleCarousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalImage from "react-modal-image";

export default class Syj extends React.Component {
    render() {
        let imageList = [
            "https://assets.41clubsofindia.org/projects/syj/1.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/2.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/3.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/4.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/5.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/6.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/7.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/8.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/9.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/10.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/11.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/12.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/13.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/14.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/15.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/16.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/17.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/18.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/19.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/20.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/21.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/22.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/23.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/24.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/25.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/26.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/27.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/28.jpeg",
            "https://assets.41clubsofindia.org/projects/syj/29.jpeg",
        ]
        return (
            <div>
                <ProjectsCarousel />
                <Container>
                    <Seo title={'Share Your Joy | 41 India'}
                         description={'Learn more about the Share Your Joy initiative of 41 India'}
                         slug={'/projects/syj'} />
                    <div className={'text-center'}>
                        {/*TODO: SYJ LOGO*/}
                    </div>
                    <p>
                        <strong>What is SHARE YOUR JOY(SYJ)?</strong><br/>
                        SYJ is a 41 Clubs initiative to encourage 41ers to take up community service activities.
                    </p>
                    <p>
                        <strong>What actives can be considered for SYJ?</strong><br/>
                        Some of the suggested SYJ activities:
                    </p>
                    <ul>
                        <li>Visit the Prospect Premises: like Schools, Old Age Homes, Orphanages etc.</li>
                        <li>Understand Requirements : Review their Requirements (other than infrastructure)</li>
                        <li>Givables under Consideration:
                            <ul>
                                <li>Food, Ration kits, Child’s Toys, Clothes, Books,</li>
                                <li>Medical Donations like Wheel Chairs, Artificial Limbs, Hospital Beds, Oxygen Machines
                                    etc.
                                </li>
                                <li>Utilities like Air Coolers, Inverters, Beds, Computers etc.,</li>
                                <li>Health & Hygiene</li>
                                <li>Skill Development</li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        For any other information and clarification, 41ers can feel free to contact.
                    </p>
                    <p>
                        41er Yogesh Sheth<br/>
                        Convenor SYJ & Fund Raising 2023-24<br/>
                        Association of 41 Clubs of India<br/>
                        Mob: 9844113750<br/>
                    </p>
                </Container>
                <Container>
                    <Row>
                        {imageList.map((item, idx) => {
                            return (
                                <Col md={3} sm={4} key={idx}>
                                    <ModalImage
                                        small={item}
                                        large={item}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}