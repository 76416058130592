import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Seo from "../../Components/Seo";

export default class RollOfHonour extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Roll of Honour | 41 India'}
                     description={'Learn about past Leadership of 41 India, the pan India service organization.'}
                     slug={'/about/roll-of-honour'} />
                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/hero/roll-of-honour.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
            <Container>
                <h1>Roll Of Honour</h1>
                <h2>Past Presidents of the Association of 41 Clubs of India</h2>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>S. No</th>
                        <th>Year of Office</th>
                        <th>Name</th>
                        <th>Club / City</th>
                        <th>Theme</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{item['year'] || item['years']}</td>
                                <td>{item['name']}</td>
                                <td>{item['club'] + ' / ' + item['city']}</td>
                                <td>{item['theme']}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </Container>
            </div>
        );
    }
}

const data = [
    {
        "year": "1980-1981",
        "name": "Indu Chandok",
        "club": "1",
        "city": "Chennai",
        "theme": ""
    },
    {
        "year": "1981-1982",
        "name": "Nainu Sekran",
        "club": "1",
        "city": "Chennai",
        "theme": ""
    },
    {
        "year": "1982-1983",
        "name": "Sagar Gulati",
        "club": "4",
        "city": "Coimbatore",
        "theme": ""
    },
    {
        "year": "1983-1984",
        "name": "Ethiraj P.N.",
        "club": "1",
        "city": "Chennai",
        "theme": ""
    },
    {
        "year": "1984-1985",
        "name": "Raja S.",
        "club": "2",
        "city": "Bangalore",
        "theme": ""
    },
    {
        "year": "1985-1986",
        "name": "Raja S.",
        "club": "2",
        "city": "Bangalore",
        "theme": ""
    },
    {
        "year": "1986-1987",
        "name": "Viji Iyeangar",
        "club": "8",
        "city": "Kolkata",
        "theme": ""
    },
    {
        "year": "1987-1988",
        "name": "Viji Iyeangar",
        "club": "8",
        "city": "Kolkata",
        "theme": ""
    },
    {
        "year": "1988-1989",
        "name": "Indu Chandok",
        "club": "1",
        "city": "Chennai",
        "theme": ""
    },
    {
        "year": "1989-1990",
        "name": "Indu Chandok",
        "club": "1",
        "city": "Chennai",
        "theme": ""
    },
    {
        "year": "1990-1991",
        "name": "Ashok Dadgupta",
        "club": "8",
        "city": "Kolkata",
        "theme": ""
    },
    {
        "year": "1991-1992",
        "name": "Gorur V.S. Murty",
        "club": "6",
        "city": "Hyderabad",
        "theme": ""
    },
    {
        "year": "1992-1993",
        "name": "Alban E. Scolt",
        "club": "12",
        "city": "Kolkata",
        "theme": "Operation Reach Out"
    },
    {
        "year": "1993-1994",
        "name": "Vidya Prakash",
        "club": "77",
        "city": "Coimbatore",
        "theme": "Communicate and Consolidate"
    },
    {
        "years": "1994-95",
        "name": "Pramod Jain",
        "club": "5",
        "city": "New Delhi",
        "theme": "Better Understanding Lasting Friendship"
    },
    {
        "years": "1995-96",
        "name": "Rajkumar V.",
        "club": "54",
        "city": "Coimbatore",
        "theme": "Reach Out to New Frontiers"
    },
    {
        "years": "1996-97",
        "name": "Pahlaj M. Nachani",
        "club": "2",
        "city": "Bangalore",
        "theme": "Live today +ve Communication"
    },
    {
        "years": "1997-98",
        "name": "Sam Sampath",
        "club": "7",
        "city": "Chennai",
        "theme": "Meet - A - Month"
    },
    {
        "years": "1998-99",
        "name": "Ghanshyam Gupta",
        "club": "5",
        "city": "New Delhi",
        "theme": "Consolidating Friendship"
    },
    {
        "years": "1999-2000",
        "name": "Kishore Sahani",
        "club": "14",
        "city": "Chennai",
        "theme": "Taking Ownership"
    },
    {
        "years": "2000-2001",
        "name": "Rajan Mittal",
        "club": "80",
        "city": "Mumbai",
        "theme": "Catch ‘em Young at 41"
    },
    {
        "years": "2001-2002",
        "name": "Ramana G.K.",
        "club": "2",
        "city": "Bangalore",
        "theme": "Let’s Communicate and Grow"
    },
    {
        "years": "2002-2003",
        "name": "Baldev Sidhu",
        "club": "5",
        "city": "New Delhi",
        "theme": "Into the Future Together"
    },
    {
        "years": "2003-2004",
        "name": "Mohan Rajes",
        "club": "32",
        "city": "Salem",
        "theme": "Reach out, Renew, Revive"
    },
    {
        "years": "2004-2005",
        "name": "Micky S. S. Oberoi",
        "club": "8",
        "city": "Kolkata",
        "theme": "Connect, Extend, Consolidate"
    },
    {
        "years": "2005-2006",
        "name": "Sameer Nayar",
        "club": "39",
        "city": "New Delhi",
        "theme": "We Care"
    },
    {
        "years": "2006-2007",
        "name": "Alok Harnathka",
        "club": "60",
        "city": "Guntur",
        "theme": "Educate A Child"
    },
    {
        "years": "2007-2008",
        "name": "Krishna Kumar N.S.",
        "club": "14",
        "city": "Chennai",
        "theme": "Extend the Chain of Friendship"
    },
    {
        "years": "2008-2009",
        "name": "Debashish Roy",
        "club": "12",
        "city": "Kolkata",
        "theme": "Go Green"
    },
    {
        "years": "2009-2010",
        "name": "Pratheep Kumar",
        "club": "71",
        "city": "Kollam",
        "theme": "Be a Friend"
    },
    {
        "years": "2010-2011",
        "name": "Blbir Verma",
        "club": "5",
        "city": "New Delhi",
        "theme": "Make Water A Friend"
    },
    {
        "years": "2011-2012",
        "name": "Sidharthan V.",
        "club": "32",
        "city": "Salem",
        "theme": "Build Relationships"
    },
    {
        "years": "2012-2013",
        "name": "Rohit Pombra",
        "club": "8",
        "city": "Kolkata",
        "theme": "Let’s Lend  Hand"
    },
    {
        "years": "2013-2014",
        "name": "Ajit Khullar",
        "club": "5",
        "city": "New Delhi",
        "theme": "Connect n Reconnect"
    },
    {
        "years": "2014-2015",
        "name": "Manoj Kapoor",
        "club": "50",
        "city": "Ahmedabad",
        "theme": "Catch the Spirit"
    },
    {
        "years": "2015-2016",
        "name": "Sridharan A.",
        "club": "42",
        "city": "Chennai",
        "theme": "Stay Connected"
    },
    {
        "years": "2016-2017",
        "name": "Gopal Chopra",
        "club": "116",
        "city": "Bangalore",
        "theme": "Cheers 41"
    },
    {
        "years": "2017-2018",
        "name": "Harsh Sehgal",
        "club": "39",
        "city": "New Delhi",
        "theme": "Communicate, Collaborate, Celebrate"
    },
    {
        "years": "2018-2019",
        "name": "Rajiv Mehra",
        "club": "110",
        "city": "Mumbai",
        "theme": "Charge Recharge"
    },
    {
        "years": "2019-2020",
        "name": "Sivaramakrishna C.",
        "club": "70",
        "city": "Vijaywada",
        "theme": "Together We Will"
    },
    {
        "years": "2020-2021",
        "name": "R P Venkatesh",
        "club": "117",
        "city": "Kodaikanal",
        "theme": "41 & Beyond"
    },
    {
        "years": "2021-2022",
        "name": "Pankaj Singh",
        "club": "89",
        "city": "Bangalore",
        "theme": "All For One One For All"
    },
    {
        "years": "2022-23",
        "name": "Bikram Kesari Singh Deo",
        "club": "37",
        "city": "Bhubaneswar",
        "theme": "Friends For Life"
    }
]