import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import React from "react";

export default class SimpleCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: this.props.images.map(image => {
                return {
                    src: image
                }
            })
        }
    }

    render() {
        return (
            <Carousel>
                {this.state.images.map((item, idx) => {
                    return (
                        <Carousel.Item interval={1000} key={idx}>
                            <img
                                className="d-block w-100"
                                src={item.src}
                                alt={item.alt}
                                style={{height: '600px', margin: 'auto', aspectRatio: '1', objectFit: 'cover'}}
                            />
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                                <p>{item.subtitle}</p>
                                {item.slug &&
                                <Button variant={'outline-secondary'} as={Link} to={item.slug}>Know More</Button>}
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        );
    }
}