import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";
import ProjectsCarousel from "./ProjectsCarousel";
import {Tabs, Tab} from "react-bootstrap";
import CustomForm from "../../Components/CustomForm";
import SimpleCarousel from "../../Components/SimpleCarousel";

export default class Ftde extends React.Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }


    handleFormSubmit(formData) {
        console.log("Form Data Submitted: ", formData);
        // Implement submission logic, possibly sending data to a server or triggering other actions
    }

    render() {
        if(window.location.search === '?env=test') {
            const fieldsForAnnexA = [
                { name: "schoolName", label: "School Name", type: 'input', required: true },
                { name: "schoolAddress1", label: "School Address 1", type: 'input', required: true },
                { name: "schoolAddress2", label: "School Address 2", type: 'input', required: true },
                { name: "state", label: "State", type: 'input', required: true },
                { name: "contactNo", label: "Contact No.", type: 'input', required: true },
                { name: "emailId", label: "Email Id", type: 'input', required: true },
                { name: "schoolCoordinates", label: "School Co-ordinates", type: 'input', required: true },
                { name: "schoolUDiscCode", label: "School U-Disc Code", type: 'input', required: true },
                { name: "educationBoard", label: "Education Board", type: 'input', required: true },
                { name: "medium", label: "Medium (English/Hindi/Regional)", type: 'input', required: true },
                { name: "nodalSchool", label: "Nodal School", type: 'input', required: true },
                { name: "principal", label: "Principal", type: 'input', required: true },
                { name: "coordinator1", label: "Co-Ordinator-1", type: 'input', required: true },
                { name: "coordinator2", label: "Co-Ordinator-2", type: 'input', required: true },
                { name: "totalTeachingSanctioned", label: "Total Teaching Sanctioned", type: 'input', required: true },
                { name: "totalTeachingStaffAlloted", label: "Total Teaching Staff Allotted", type: 'input', required: true },
                { name: "shortfallOfTeachingStaff", label: "Shortfall of Teaching Staff", type: 'input', required: true },
                { name: "noOfTeachersWithEmailId", label: "No of teachers with email id and basic IT Skills", type: 'input', required: true },
                { name: "numberOfStudentsWithEmailId", label: "Number of Students with Email id & Basic IT Skills", type: 'input', required: true },
                { name: "studentCountForSchool", label: "Student Count for School", type: 'input', required: true },
                { name: "noOfStudentsUsingICT", label: "No Of Students using ICT for learning", type: 'input', required: true },
                { name: "existingInfrastructureDetails", label: "Existing Infrastructure Details", type: 'input', required: true },
                { name: "certification", label: "I hereby certify that the information provided by me is correct and reviewed by me.", type: 'checkbox', required: true },
                { name: "place", label: "Place", type: 'input', required: true },
                { name: "date", label: "Date", type: 'date', required: true },
                { name: "principalSignature", label: "Principal's Signature with rubber stamp", type: 'input', required: true }
            ];

            const fieldsForAnnexB = [
                { name: "applicationNo", label: "Application No: (For office purpose only)", type: 'input', required: false },
                { name: "applicant41ClubNameNumberArea", label: "Applicant 41 Club Name, Number and Area Number", type: 'input', required: true },
                { name: "rtiLciTangentClubNameNumber", label: "RTI / LCI / Tangent Club Name & Number", type: 'input', required: false },
                { name: "otherOrganizationName", label: "Any Other Organization / NGO", type: 'input', required: false },
                { name: "donorName", label: "Donor Name", type: 'input', required: false },
                { name: "address1", label: "Address 1", type: 'input', required: false },
                { name: "address2", label: "Address 2", type: 'input', required: false },
                { name: "pan", label: "PAN", type: 'input', required: false },
                { name: "mobileNumber", label: "Mobile Number", type: 'input', required: true },
                { name: "emailId", label: "Email Id", type: 'input', required: true },
                { name: "status", label: "Status: Ind./Company/Trust/Society", type: 'input', required: false },
                { name: "schoolNameOfFtdeProject", label: "School Name of FTDE Project", type: 'input', required: true },
                { name: "ftdeProjectLocationAddress", label: "FTDE Project Location/Address", type: 'input', required: true },
                { name: "projectCoordinator41erNameMobile", label: "Project Co-ordinator 41er Name & Mobile No.", type: 'input', required: true },
                { name: "setupVendorSelected", label: "Setup / Vendor Selected (Attach Quotation)", type: 'input', required: true },
                { name: "clubDuesPaid", label: "Whether Club Dues (AAF) to Nationals are paid", type: 'checkbox', required: false },
                { name: "dateOfStartOfProject", label: "Date of Start of Project", type: 'date', required: false },
                { name: "dateOfInauguration", label: "Date of Inauguration", type: 'date', required: false },
                { name: "anyOtherInformationSuggestionObservation", label: "Any Other Information /suggestion/observation by Applicant Chairman", type: 'textarea', required: false },
                { name: "clubChairmanConfirmation", label: "I Club Chairman 41er confirm that I have or fellow Club Members have physically visited the school and found it to be worthy for the FTDE project.", type: 'checkbox', required: true },
                { name: "agreementToDepositShareOfProjectCost", label: "I agree to deposit our club's share of the project cost immediately on receipt of Project Approval from Nationals.", type: 'checkbox', required: true },
                { name: "agreementToSendProjectCompletionReport", label: "I agree to send the Project Completion Report with necessary details within 7 days of the inauguration and completion of the project.", type: 'checkbox', required: true },
                { name: "chairmanSignature", label: "Chairman's Signature", type: 'input', required: true },
                { name: "place", label: "Place", type: 'input', required: true },
                { name: "date", label: "Date", type: 'date', required: true }
            ];

            const fieldsForAnnexC = [
                { name: "nameAndNumber41Club", label: "Name and Number of the 41 Club", type: 'input', required: true },
                { name: "nameOfFtdeProject", label: "Name of the FTDE Project", type: 'input', required: true },
                { name: "dateOfProposalForm", label: "Date of Proposal Form", type: 'date', required: true },
                { name: "setupVendorSelected", label: "Setup / Vendor Selected (Attach Quotation)", type: 'input', required: true },
                { name: "proposedDateOfCommencementOfProject", label: "Proposed Date of Commencement Of Project", type: 'date', required: true },
                { name: "remarksAreaChairman", label: "Remarks – Any information Area Chairman would like to share about the Project", type: 'textarea', required: false },
                // Vendor details or selections as provided in the document
                { name: "vendorQuotes", label: "Vendor Quotations and Selections", type: 'multiline', required: true },
                { name: "areaChairmanRecommendation", label: "I verify above Details as true and recommend that Proposal be taken up for processing", type: 'checkbox', required: true },
                { name: "areaChairmanInformClubProjectBranding", label: "I have also informed the club that Project is to be branded to comply with Statutory Documentation & Compliances as applicable", type: 'checkbox', required: true },
                { name: "areaChairmanSignature", label: "Area Chairman Signature", type: 'input', required: true },
                { name: "mobile", label: "Mobile", type: 'input', required: true },
                { name: "place", label: "Place", type: 'input', required: true },
                { name: "date", label: "Date", type: 'date', required: true }
            ];

            const fieldsForAnnexD = [
                { name: "vendorName", label: "Vendor Name", type: 'input', required: true },
                { name: "receiptAmount", label: "Receipt of Amount", type: 'input', required: true },
                { name: "amountInWords", label: "Amount in Words", type: 'input', required: true },
                { name: "paymentDate", label: "Payment Date", type: 'date', required: true },
                { name: "paymentMethod", label: "Payment Method (RTGS/NEFT)", type: 'input', required: true },
                { name: "schoolNameForInstallation", label: "School Name for Installation", type: 'input', required: true },
                { name: "clubNumberForInstallation", label: "41 Club Number for Installation", type: 'input', required: true },
                { name: "taxInvoiceNo", label: "Tax Invoice No", type: 'input', required: true },
                { name: "taxInvoiceDate", label: "Tax Invoice Date", type: 'date', required: true },
                { name: "ewayBillNo", label: "eWay Bill Number", type: 'input', required: true },
                { name: "ewayBillDate", label: "eWay Bill Date", type: 'date', required: true },
                { name: "vendorSignature", label: "Vendor's Signature", type: 'input', required: true },
                { name: "vendorStamp", label: "Vendor's Stamp", type: 'input', required: false },
                { name: "place", label: "Place", type: 'input', required: true },
                { name: "date", label: "Date", type: 'date', required: true }
            ];

            const fieldsForAnnexE = [
                { name: "clubNumber", label: "Club Number", type: 'input', required: true },
                { name: "projectCompletionInform", label: "Inform Project Completion", type: 'textarea', required: true },
                { name: "schoolNameAddress", label: "Name & Address of School", type: 'input', required: true },
                { name: "principalContactDetails", label: "Principal's Contact Details", type: 'input', required: true },
                { name: "coordinatorContactDetails", label: "Coordinator's Contact Details", type: 'input', required: true },
                { name: "reportFromVendor", label: "Report from Vendor", type: 'checkbox', required: false },
                { name: "confirmationLetterSchool", label: "Confirmation Letter by School Authorities", type: 'checkbox', required: false },
                { name: "geoTaggedPhoto", label: "Geo Tagged Photo of Engineer with School Principal & Club Officer Bearer", type: 'checkbox', required: false },
                { name: "photoWithChiefGuest", label: "Photo with Chief Guest/Area Chairman in the Digital Classroom", type: 'checkbox', required: false },
                { name: "photoOfInauguralPlaque", label: "Photo of Inaugural Plaque with FTDe logo", type: 'checkbox', required: false },
                { name: "furtherDetailsFromVendor", label: "Further Details Required from Vendor", type: 'textarea', required: false },
                { name: "signedReceiptFromVendor", label: "Signed Receipt from Vendor for Balance Amount Advance", type: 'checkbox', required: false },
                { name: "signedOriginalGSTInvoice", label: "Signed Original GST Invoice", type: 'checkbox', required: false },
                { name: "ewayBillCopy", label: "E-Way Bill Copy", type: 'checkbox', required: false },
                { name: "signedReceiptForAdvance", label: "Signed Receipt from Vendor for Advance Paid", type: 'checkbox', required: false },
                { name: "observationsSuggestions", label: "Any Observations/Suggestions by Club Chairman", type: 'textarea', required: false },
                { name: "documentsCheckedAttached", label: "Checked and Attached all Documents as per S.N. 4 to 11 of this Project Completion Report", type: 'checkbox', required: true },
                { name: "clubChairmanSignature", label: "Signature of Club Chairman", type: 'input', required: true },
                { name: "clubChairmanName", label: "Name", type: 'input', required: true },
                { name: "clubChairmanMobileNo", label: "Mobile No", type: 'input', required: true },
                { name: "place", label: "Place", type: 'input', required: true },
                { name: "date", label: "Date", type: 'date', required: true }
            ];


            return (
                <div>
                    <ProjectsCarousel />
                    <Container>
                        <Seo title={'Freedom Through Digital Education | 41 India'}
                             description={'Learn more about the Freedom Through Digital Education initiative of 41 India'}
                             slug={'/projects/ftde'} />
                        <div className={'text-center'}>
                            <h1>Freedom through Digital Education</h1>
                        </div>
                        <Tabs defaultActiveKey="info">
                            <Tab eventKey="info" title="About">
                                Click <a href={'TODO'}>here</a> to view information about FTDE and to review the application process
                            </Tab>
                            <Tab eventKey='annex-a' title="Annex A">
                                <CustomForm fields={fieldsForAnnexA} onSubmit={this.handleFormSubmit} />
                            </Tab>
                            <Tab eventKey='annex-b' title="Annex B">
                                <CustomForm fields={fieldsForAnnexB} onSubmit={this.handleFormSubmit} />
                            </Tab>
                            <Tab eventKey='annex-c' title="Annex C">
                                <CustomForm fields={fieldsForAnnexC} onSubmit={this.handleFormSubmit} />
                            </Tab>
                            <Tab eventKey='annex-d' title="Annex D">
                                <CustomForm fields={fieldsForAnnexD} onSubmit={this.handleFormSubmit} />
                            </Tab>
                            <Tab eventKey='annex-e' title="Annex E">
                                <CustomForm fields={fieldsForAnnexE} onSubmit={this.handleFormSubmit} />
                            </Tab>
                            <Tab eventKey='sample-plaque' title="Sample Plaque">
                                Click <a href={'TODO'}>here</a> to view a sample Plaque.
                            </Tab>
                        </Tabs>
                    </Container>
                </div>
            );
        }

        let imageList = [
            'https://assets.41clubsofindia.org/projects/ftde/ftde1.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde2.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde3.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde4.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde5.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde6.jpeg',
            'https://assets.41clubsofindia.org/projects/ftde/ftde7.jpeg',
        ]

        return (
            <div>
                {/*<ProjectsCarousel />*/}
                <SimpleCarousel images={imageList} />
                <Container className={'text-center'}>
                    <Seo title={'Freedom Through Digital Education | 41 India'}
                         description={'Learn more about the Freedom Through Digital Education initiative of 41 India'}
                         slug={'/projects/ftde'} />
                    <h1>Freedom through Digital Education</h1>
                    <img src={'https://assets.41clubsofindia.org/ftde.jpeg'} width={'90%'}/>
                </Container>
            </div>
        );
    }
}