import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ticker from "../Components/Ticker";
import Seo from "../Components/Seo";
import Carousel from 'react-bootstrap/Carousel';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: 75,
            clubs: 250,
            members: 3500,
        }
    }

    render() {
        return (
            <div>
                <Seo title={'41 India'}
                     description={'41 India is a leading national service organization comprising of retired members of Round Table India'}
                     slug={'/'} />
                <HeaderCarousel />
                <Container>
                    <Row className={'text-center'}>
                        <h1>Welcome to 41 India</h1>
                    </Row>
                    <Row className={'text-center'} style={{paddingBottom: '5%'}}>
                        <h2>Creating a Better India</h2>
                    </Row>
                    <Row className={'text-center'}>
                        <Col md>
                            <div style={{height: '30%', margin: 'auto', textAlign: 'center'}}>
                                <img src={'https://assets.41clubsofindia.org/areas.png'} height={'100%'}/>
                            </div>
                            <div style={{fontSize: "xxx-large"}}>
                                <strong><Ticker number={this.state.cities}/></strong>
                            </div>
                            <div>
                                Cities
                            </div>
                        </Col>
                        <Col md>
                            <div style={{height: '30%', margin: 'auto', textAlign: 'center'}}>
                                <img src={'https://assets.41clubsofindia.org/clubs.png'} height={'100%'}/>
                            </div>
                            <div style={{fontSize: "xxx-large"}}>
                                <strong><Ticker number={this.state.clubs} plus={true}/></strong>
                            </div>
                            <div>
                                Clubs
                            </div>
                        </Col>
                        <Col md>
                            <div style={{height: '30%', margin: 'auto', textAlign: 'center'}}>
                                <img src={'https://assets.41clubsofindia.org/members.png'} height={'100%'}/>
                            </div>
                            <div style={{fontSize: "xxx-large"}}>
                                <strong><Ticker number={this.state.members} large={true} plus={true}/></strong>
                            </div>
                            <div>
                                Members
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div style={{marginLeft: '20%', marginRight: '20%', width: '60%'}}>
                            <h2>Aims & Objects</h2>
                            <ul>
                                <li>To actively enhance the bonds of Friendship established in Round Table.</li>
                                <li>To strengthen the ties with Round Table by contributing to their initiatives and
                                    projects.
                                </li>
                                <li>To extend the scope of Young Ambassador Program (YAP)</li>
                                <li>To promote international fellowship and friendship.</li>
                                <li>To actively participate in creating a better India.</li>
                            </ul>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}


function HeaderCarousel() {
    let items = [
        {
            src: "https://assets.41clubsofindia.org/hero/2024/logo.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-1.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-2.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-3.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-4.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-5.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-6.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-7.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-8.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-9.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-10.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-11.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-12.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-13.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/2024/hero-14.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
    ]
    return (
        <Carousel>
            {items.map((item, idx) => {
                return (
                    <Carousel.Item interval={1000} key={idx}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt}
                            style={{height: '600px', margin: 'auto', aspectRatio: '1', objectFit: 'cover'}}
                        />
                        <Carousel.Caption>
                            <h3>{item.title}</h3>
                            <p>{item.subtitle}</p>
                            <Button variant={'outline-secondary'} as={Link} to={item.slug}>Know More</Button>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}