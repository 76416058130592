import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class About extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'About Us | 41 India'} description={'Learn about 41 India, the pan India service organization, and the work they do'} slug={'/about'} />
                {/*<div style={{minHeight: '404px',*/}
                {/*    position: 'relative',*/}
                {/*    background: 'url("https://assets.41clubsofindia.org/hero/about.jpeg")',*/}
                {/*    backgroundPosition: 'center center',*/}
                {/*    backgroundSize: 'cover'*/}
                {/*}}></div>*/}
                <HeaderCarousel />
                <Container>
                    <h1>
                        About 41 India
                    </h1>
                    <p>
                        41 India is a pan India service organisation made up of past Tablers (members of Round Table
                        India) above the age of 40 years. 41 India is a fellowship organisation and works closely with
                        the Tables across India to serve the needy and underprivileged
                    </p>
                    <p>
                        41 India has over 220 active chapters (called 41 Clubs) spread in over 75 cities across the
                        country with over 3,000 members. Membership is open only to past Tablers over the age of 40.
                        It’s an association for life
                    </p>
                    <p>
                        41 India has 41 Clubs (chapters in India) as its members and individual members or 41ers are
                        members of the Clubs. 41ers are entrepreneurs & successful businessmen in SME/MSME sector,
                        successful senior executives in large corporations and hold position of prominence in various
                        business bodies like YPO, CII, ASSOCHAM, BNI and Rotary amongst others. They are also successful
                        professionals like Chartered Accountants, Lawyers, Architects, Trainers and Coaches They are
                        thought leaders, successful individuals with large hearts. They contribute their time, money &
                        efforts for betterment of the society as well as their brethren & their families
                    </p>
                </Container>
            </div>
        );
    }
}

function HeaderCarousel() {
    let items = [
        {
            src: "https://assets.41clubsofindia.org/hero/hero-1.jpeg",
            alt: "",
            title: 'Creating a Better India',
            subtitle: 'The aim is to promote service, fellowship and goodwill.',
            slug: '/about'
        },
        {
            src: "https://assets.41clubsofindia.org/hero/hero-2.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/hero-3.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/hero/hero-4.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        // {
        //     src: "https://assets.41clubsofindia.org/hero/hero-2.jpeg",
        //     alt: "",
        //     title: 'Freedom Through Digital Education',
        //     subtitle: 'Our long term project.',
        //     slug: '/projects/ftde'
        // },
        // {
        //     src: "https://assets.41clubsofindia.org/hero/hero-3.jpeg",
        //     alt: "",
        //     title: 'Extending a Helping Hand',
        //     subtitle: 'To those impacted by Covid 19 pandemic',
        //     slug: '/communication/others'
        // },
        // {
        //     src: "https://assets.41clubsofindia.org/hero/hero-4.jpeg",
        //     alt: "",
        //     title: 'Team That Makes It Happen',
        //     subtitle: 'Our National Board',
        //     slug: '/about/national-board'
        // },
        // {
        //     src: "https://assets.41clubsofindia.org/hero/hero-5.jpeg",
        //     alt: "",
        //     title: 'Protecting Our Front-line Covid Warriors',
        //     subtitle: '41 India did Covid relief projects worth Rs 10+ crores',
        //     slug: '/communication/others'
        // },
    ]
    return (
        <Carousel>
            {items.map((item, idx) => {
                return (
                    <Carousel.Item interval={1000} key={idx}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt}
                            style={{height: '600px', margin: 'auto', aspectRatio: '1', objectFit: 'cover'}}
                        />
                        <Carousel.Caption>
                            <h3>{item.title}</h3>
                            <p>{item.subtitle}</p>
                            <Button variant={'outline-secondary'} as={Link} to={item.slug}>Know More</Button>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}