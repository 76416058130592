import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Seo from "../Components/Seo";
import CustomForm from "../Components/CustomForm";

export default class Donate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(formData, fields, formName) {
        fetch('https://v9usf0lc3f.execute-api.ap-south-1.amazonaws.com/form/all', {
            method: 'POST',
            body: JSON.stringify({formName: formName, fields: fields, responses: formData, emails: ['office41india@gmail.com']})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    success: 'Your form has been submitted'
                })
            })
        console.log("Form Data Submitted: ", formData);
        // Implement submission logic, possibly sending data to a server or triggering other actions
    }

    render() {
        const fields = [
            { name: "name", label: "Name", type: 'input', required: true },
            { name: "clubInfo", label: "Current Club Number / Name (If Applicable)", type: 'input', required: false },
            { name: "mobileNumber", label: "Mobile Number", type: 'input', required: true },
            { name: "email", label: "Email Address", type: 'email', required: true },
        ];

        return (
            <div>
                <Container>
                    <Seo title={'Donate | 41 India'}
                         description={'Donate to 41 India'}
                         slug={'/donate'} />
                    <CustomForm submitted={this.state.success} fields={fields} title={'Donate Form'} onSubmit={(e) => this.handleFormSubmit(e, fields, "Member Transfer Form")} />
                </Container>
            </div>
        );
    }
}