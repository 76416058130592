import React from "react";
import Seo from "../Components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import ModalImage from "react-modal-image";

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            loading: true
        }
    }

    componentDidMount() {
        fetch('https://hth8094we5.execute-api.ap-south-1.amazonaws.com/events')
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    loading: false,
                    events: resp
                })
            })
    }


    render() {
        return (
            <Container>
                <Seo title={'Events | 41 India'}
                     description={'Learn more about the upcoming Events of 41 India'}
                     slug={'/events'} />
                <h1>
                    41 India Events
                </h1>
                <Row>
                    {this.state.events.map((event, idx) => {
                        return (
                            <Card style={{ width: '22rem', margin: 'auto', marginTop: '1%', marginBottom: '1%'}} key={idx}>
                                {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                                <Card.Body>
                                    <Card.Title>{event.title}</Card.Title>
                                    <Card.Text>
                                        Location: {event.location}<br/>
                                        Start: {cleanTime(event.startTime)}<br/>
                                        End: {cleanTime(event.endTime)}<br/><br/>

                                        {event.description}
                                    </Card.Text>
                                    {/*<Button variant="primary">Go somewhere</Button>*/}
                                </Card.Body>
                            </Card>
                        )
                    })}

                </Row>
                <Row>
                    <News />
                </Row>
            </Container>
        );
    }
}

function cleanTime(input) {
    return input.split('T')[0].replaceAll('-', '/') + ' ' + input.split('T')[1]
}

function News() {
    let items = [
        "https://assets.41clubsofindia.org/events/news/Hangal 1.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 2.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 3.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 4.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 5.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 6.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 7.jpeg",
        "https://assets.41clubsofindia.org/events/news/Hangal 8.jpeg",
        "https://assets.41clubsofindia.org/events/news/Mysore 1.jpeg",
        "https://assets.41clubsofindia.org/events/news/Mysore 2.jpeg",
        "https://assets.41clubsofindia.org/events/news/Mysore 3.jpeg",
        "https://assets.41clubsofindia.org/events/news/NP BAreilly.jpeg",
    ]
    return (
        <>
            <h2>41 India in the News</h2>
        <Row>
            {items.map((item, idx) => {
                return (
                    <Col md={3} sm={4} key={idx}>
                        <ModalImage
                            small={item}
                            large={item}
                        />
                    </Col>
                )
            })}
        </Row>
        </>
    );
}















