import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Table from "react-bootstrap/Table";

export default class History extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'H̱istory | 41 India'}
                     description={'Learn about the history 41 India, the pan India service organization.'}
                     slug={'/about/history'} />
                {/*<div style={{minHeight: '404px',*/}
                {/*    position: 'relative',*/}
                {/*    background: 'url("https://assets.41clubsofindia.org/hero/about.jpeg")',*/}
                {/*    backgroundPosition: 'center center',*/}
                {/*    backgroundSize: 'cover'*/}
                {/*}}></div>*/}
                <HeaderCarousel />
                <Container>
                    <h1>History of 41 India</h1>
                    <p>On 18th March 1972, Barry W Kay, Chairman of Manchester and Stockport 41 Club, brought a letter from Arnold Lucas, the then President of Ex-Tablers, UK, inviting India to start the Ex-Tablers Movement.</p>
                    <p>A meeting was held at the Delhi Gymkhana Club with four Past Presidents of Round Table India - Viren Khare, Bharat Bhushan, Kavi Singh, and Jiti Singh, along with Ex-Tablers SK (Slick) Das Gupta and Indu Chandhok. Indu, who had just left Round Table, was appointed the Secretary/Treasurer of a non-existent 41 Club. A news item regarding the 41 Club also appeared in a local Delhi Daily.</p>
                    <p>There were three Round Tables in Madras at that time: Nos 1, 3, and 10. Thirteen Ex-Tablers from these three Tables met at the residence of Speedy Jayendra Patel in September of the same year, and the Minutes of the 24th June 1972 meeting have been recorded for posterity. This club was called 41 Club Madras. The number 1 was allotted after other clubs were formed. The meeting elected JN Patel as the Chairman, SN Patel as the Vice Chairman, and Indu Chandhok as the Secretary/Treasurer.</p>
                    <p>For the next eight years till 1980, the growth was very slow, and the formation of the next 4 Clubs took considerable time (Bangalore No. 2, Mumbai No. 3, Coimbatore No. 4, and New Delhi No. 5). To be recognized as a National Association, five Clubs were required. This finally happened in 1980, and Indu Chandhok (Madras No. 1) took on the role of the President of the Association.</p>
                    <p>During 1980-81, three more clubs were formed (Hyderabad No. 6, Madras No. 7, and Calcutta No. 8).</p>
                    <p>In 1981, the first AGM of the Association of 41 Clubs of India was held at the Madras Gymkhana Club. Nainu Sekharan (Madras No. 1) was elected as the second President by a majority vote at the AGM meeting held on 7th Feb 1981. The Vice President was Sagar Gulati (Coimbatore), Lal Tahilramani the Secretary, and KV Kesu the Treasurer. As Past president, Indu assumed the role of IRO.</p>
                    <p>In 1982, the National Association of 41 Clubs of India was affiliated with the International Association during the Presidency of V Sagar Gulati (Coimbatore).</p>
                    <p>Lal Tahilramani, the 1st National Secretary, convinced all concerned that unless there were some rules, the organization would not grow. Thus his first rules were adopted and are reproduced with the history of the Association. The spirit of the original rules has been retained, and the emphasis has been on continuing friendships established through the Round Table Years.</p>
                    <p>After Nainu Sekharan, the attempt was to rotate the President ship to different parts of the country. Thus Nainu was followed by V Sagar Gulati (Coimbatore), P.N. Ethiraj (Madras), S. Raja (Bangalore), and Viji Iyengar (Club 8 - Calcutta). Since there was no candidate to follow, Viji had to keep the post for two years 86-88.</p>
                    <p>Indu Chandhok (Club 1 - Madras) was recalled to be President in 1988 and held on till 1990 when Ashok Das Gupta (Calcutta) agreed to be President.</p>
                    <p>He was followed by Gorur Shyam Murthy (Hyderabad) and Alban E Scott (Calcutta) in 1993.</p>
                    <p>Thanks to Viji's initiative, Fellowship magazine of the Association was started in 1987, with Alban Scott of Calcutta as the first Editor.</p>
                    <p>In 1991, Shyam as National President attended the 41 International meeting in the UK and put up the name of Indu Chandhok from India as International President. Indu thus became the first International President in 1991 and was followed ten years later by Sam Sampath.</p>
                    <p>During Indu’s stint as International President and Kishore Shahani as his International Secretary, the YAP programme was launched. India hosted its first International AGM in April 92.</p>
                    <p>With the efforts put in by Presidents Vidya Prakash (Club 77 - Coimbatore), Pramod Jain (Club 5 - New Delhi), and V. Rajkumar (Club 22 - Coimbatore), there was a big spurt in the growth of the movement. These younger 41ers were in touch with the next generation of Tablers, and the Association rapidly grew in ten years from 1993 Vidya Prakash’s time to Mohan Rajes’s in 2003.</p>
                    <p>During the ten years, the change from being forced to be a President of the National Association to keenness to take charge and help the growth was seen from the improved attendance at the following AGMs. Subsequent Presidents were P.M. Nichani (Club 2 - Bangalore), S. Sampath (Club 7 - Chennai), Ghanshyam Gupta (Club 5 - New Delhi), Kishore Shahani (Chennai), Rajan Mittal (Club 80 - Mumbai), G. K. Ramana (Club 10 - Bangalore), Baldev S Sidhu (Club 5 - New Delhi), and Mohan Rajes (Club 32 - Yercaud).</p>
                    <p>Mohan Rajes not only helped the growth but put his best foot forward for the organization to grow on the right lines. He structured the movement, introduced demarcated areas, introduced a Handbook, detailing the portfolios of work for the Secretariat and the National Office Bearers, and emphasized the need for effective Area Convenors.</p>
                    <p>A Mid-Term Meet was also introduced in all the Areas.</p>
                    <p>Sridharan A (Chettiar) aggressively managed and looked after the funds during these critical years, and the Association boasts of a strong foundation.</p>
                    <p>Presidents in the following years were Micky Oberoi (Club 8 - Kolkata), Sameer Nayyar (Club 39 - New Delhi), Alok Harnathka (Guntur), Krishna Kumar NS (Club 14 - Chennai), Debashis Roy (Club 12 - Kolkata), Pratheep Kumar (Club 71 - Kollam), Balbir Verma (Club 5 - New Delhi), Dr. V Siddharthan (Club 32 - Salem), Rohit Pombra (Kolkata), Ajit Khullar (Club 5 - New Delhi), Dr. Manoj Kapoor (Club 50 - Ahmedabad), Sridharan Chettiar (Club 42 - Chennai), Gopal Chopra (Club 116 - Bengaluru), Harsh Sehgal (Club 39 - New Delhi), Rajiv Mehra (Club 110 - Mumbai), Siva Rama Krishna Babji (Club 70 - Vijayawada), RP Venkatesh (Club 117 - Vijayawada), Pankaj Singh (Club 89 - Bangalore), Bikram Kesari Singh Deo (Club 37 - Bhubaneswar), and Srinivasu Saraswatula (Club 213 - Mumbai).</p>
                    <p>The setting up of the central Secretariat at Bob Chandran Center, Chennai, has made the task of all the Presidents easier.</p>

                    <h2>Overview</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Name/Club/Theme</th>
                                <th>AGM</th>
                                <th>City</th>
                                <th>Convenor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1980-1981</td>
                                <td>Indu Chandhok (Chennai 1)</td>
                                <td>1st</td>
                                <td>Chennai</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1981-1982</td>
                                <td>Sagar Gulati (Coimbatore 4)</td>
                                <td>2nd</td>
                                <td>Bangalore</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1982-1983</td>
                                <td>Nainu Sekharan (Chennai 1)</td>
                                <td>3rd</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1983-1984</td>
                                <td>P N Ethiraj (Chennai 1)</td>
                                <td>4th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1984-1985</td>
                                <td>S (Baba) Raja (Bangalore 2)</td>
                                <td>5th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1985-1986</td>
                                <td>S (Baba) Raja (Bangalore 2)</td>
                                <td>6th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1986-1987</td>
                                <td>Viji Iyengar (Kolkata 8)</td>
                                <td>7th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1987-1988</td>
                                <td>Viji Iyengar (Kolkata 8)</td>
                                <td>8th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1988-1989</td>
                                <td>Indu Chandhok (Chennai 1)</td>
                                <td>9th</td>
                                <td>Kolkata</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1989-1990</td>
                                <td>Indu Chandhok (Chennai 1)</td>
                                <td>10th</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1990-1991</td>
                                <td>Ashok Das Gupta (Kolkata 8) <br/> (Friendship Forever)</td>
                                <td>11th</td>
                                <td>Kolkata</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1991-1992</td>
                                <td>Gorur V S Murthy (Hyderabad 6)</td>
                                <td>12th</td>
                                <td>Chennai</td>
                                <td>Sam Sampath</td>
                            </tr>
                            <tr>
                                <td>1992-1993</td>
                                <td>Alban E Scolt (Kolkata 12) <br/> (Operation Reach Out)</td>
                                <td>13th</td>
                                <td>Kolkata</td>
                                <td>(Reach Out 93)</td>
                            </tr>
                            <tr>
                                <td>1993-1994</td>
                                <td>Vidya Prakash (Coimbatore 77) <br/> (Communicate & Consolidate)</td>
                                <td>14th</td>
                                <td>Ooty</td>
                                <td>(Ooty Frooty)</td>
                            </tr>
                            <tr>
                                <td>1994-1995</td>
                                <td>Pramod Jain (New Delhi 5) <br/> (Better Understanding - Lasting Friendships)</td>
                                <td>15th</td>
                                <td>Dehradun</td>
                                <td>Sameer Nayyar <br/> (Doon Dhamaka)</td>
                            </tr>
                            <tr>
                                <td>1995-1996</td>
                                <td>V Rajkumar (Coimbatore 22) <br/> (Reach Out To New Frontiers)</td>
                                <td>16th</td>
                                <td>Coimbatore</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1996-1997</td>
                                <td>Pahlaj M Nichani (Bangalore 2) <br/> (Live Today With +ive Communication)</td>
                                <td>17th</td>
                                <td>Bangalore</td>
                                <td>(B-There)</td>
                            </tr>
                            <tr>
                                <td>1997-1998</td>
                                <td>Sam Sampath (Chennai 7) <br/> (Meet - A - Month)</td>
                                <td>18th</td>
                                <td>Chennai</td>
                                <td>(ZIMbly Super)</td>
                            </tr>
                            <tr>
                                <td>1998-1999</td>
                                <td>Ghanshyam Gupta (New Delhi 5) <br/> (Consolidating Friendship)</td>
                                <td>19th</td>
                                <td>New Delhi</td>
                                <td>(Dilli Dharkan)</td>
                            </tr>
                            <tr>
                                <td>1999-2000</td>
                                <td>Kishore Shahani (Chennai 140 <br/> (Taking Ownership)</td>
                                <td>20th</td>
                                <td>Chennai</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2000-2001</td>
                                <td>Rajan Mittal (Mumbai 80) <br/> (Catch 'em Young at 41)</td>
                                <td>21st</td>
                                <td>Mumbai</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2001-2002</td>
                                <td>G K Ramana (Bangalore 2) <br/> (Let's Communicate & Grow)</td>
                                <td>22nd</td>
                                <td>Bangalore</td>
                                <td>(Bangalore Bunny)</td>
                            </tr>
                            <tr>
                                <td>2002-2003</td>
                                <td>Baldev Singh Sidhu (New Delhi 5) <br/> (Into The Future Together)</td>
                                <td>23rd</td>
                                <td>New Delhi</td>
                                <td>Ashwani Khemka <br/> (Rajdhani Rendezvous)</td>
                            </tr>
                            <tr>
                                <td>2003-2004</td>
                                <td>Mohan Rajes (Salem 32) <br/> (Reach out - Renew - Revive)</td>
                                <td>24th</td>
                                <td>Yercaud</td>
                                <td>V Sekar <br/> (Jungle-Mingle)</td>
                            </tr>
                            <tr>
                                <td>2004-2005</td>
                                <td>S S Micky Oberoi (Kolkata 80 <br/> (Connect, Extend, Consolidate)</td>
                                <td>25th</td>
                                <td>Kolkata</td>
                                <td>(Kolkata Karishma)</td>
                            </tr>
                            <tr>
                                <td>2005-2006</td>
                                <td>Sameer Nayyar (New Delhi 39) <br/> (We Care)</td>
                                <td>26th</td>
                                <td>Agra</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2006-2007</td>
                                <td>Alok Harnathka (Guntur 60) <br/> (Educate A Child)</td>
                                <td>27th</td>
                                <td>Hyderabad</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2007-2008</td>
                                <td>N S Krishna Kumar (Chennai 1) <br/> (Extend The Chain Of Friendship)</td>
                                <td>28th</td>
                                <td>Chennai</td>
                                <td>Ramkumar <br/> (Naughty Forty)</td>
                            </tr>
                            <tr>
                                <td>2008-2009</td>
                                <td>Debashis Roy (Kolkata 12) <br/> (Go Green)</td>
                                <td>29th</td>
                                <td>Kolkata</td>
                                <td>Raja Shah <br/> (Big Bong Binge)</td>
                            </tr>
                            <tr>
                                <td>2009-2010</td>
                                <td>Pratheep Kumar (Quilon 71) <br/> (Be A Friend)</td>
                                <td>30th</td>
                                <td>Kochi</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2010-2011</td>
                                <td>Balbir Verma (New Delhi 5) <br/> (Make Water A Friend)</td>
                                <td>31st</td>
                                <td>New Delhi</td>
                                <td>Bhagwan Bhambani <br/> (Rang Rangili Dilli Chaupal)</td>
                            </tr>
                            <tr>
                                <td>2011-2012</td>
                                <td>V Siddharthan (Salem 32) <br/> (Building relationships)</td>
                                <td>32nd</td>
                                <td>Coimbatore</td>
                                <td>Ilango K <br/> (Leg Spin)</td>
                            </tr>
                            <tr>
                                <td>2012-2013</td>
                                <td>Rohit Pombra (Kolkata 8) <br/> (Let's Lend A Hand)</td>
                                <td>33rd</td>
                                <td>Bhubaneswar</td>
                                <td><br/> (Odisha Odyssey)</td>
                            </tr>
                            <tr>
                                <td>2013-2014</td>
                                <td>Ajit Khullar (New Delhi 5) <br/> (Connect N Reconnect)</td>
                                <td>34th</td>
                                <td>Jaipur</td>
                                <td>Ashwani Khemka</td>
                            </tr>
                            <tr>
                                <td>2014-2015</td>
                                <td>Manoj Kapoor (Ahmedabad 50) <br/> (Catch The Spirit. CARE)</td>
                                <td>35th</td>
                                <td>Goa</td>
                                <td>Niketan Sanghvi <br/> (Amigos-E-Fiesta)</td>
                            </tr>
                            <tr>
                                <td>2015-2016</td>
                                <td>A Sridharan (Chennai 42) <br/> (Stay Connected)</td>
                                <td>36th</td>
                                <td>Bangalore</td>
                                <td><br/> (Utsav)</td>
                            </tr>
                            <tr>
                                <td>2016-2017</td>
                                <td>Gopal Chopra (Bangalore 116) <br/> (Cheers 41)</td>
                                <td>37th</td>
                                <td>Hyderabad</td>
                                <td>Jawahar Vadlamani <br/> (Lajawab Hyderabad)</td>
                            </tr>
                            <tr>
                                <td>2017-2018</td>
                                <td>Harsh Sehgal (New Delhi 39) <br/> (Communicate, Collaborate, Celebrate-3Cs)</td>
                                <td>38th</td>
                                <td>Chandigarh</td>
                                <td>Navin Gupta <br/> (Oye Balle Balle)</td>
                            </tr>
                            <tr>
                                <td>2018-2019</td>
                                <td>Rajiv Mehra (Mumbai 110) <br/> (Charge Recharge)</td>
                                <td>39th</td>
                                <td>Goa</td>
                                <td>Sanjiv Shah <br/> (Dum Dum Diga Diga)</td>
                            </tr>
                            <tr>
                            <td>2019-2020</td>
                            <td>Sivaramakrishna (Babji) (Vijayawada 70) <br/> (Together We Will)</td>
                            <td>40th</td>
                            <td>Zoom</td>
                            <td>Maninder Singh</td>
                        </tr>
                        <tr>
                            <td>2020-2021</td>
                            <td>R P Venkatesh (Kodai 117) <br/> (41 & Beyond)</td>
                            <td>41st</td>
                            <td>Chennai</td>
                            <td>Padmashish Prasad <br/> (Reflections 41)</td>
                        </tr>
                        <tr>
                            <td>2021-2022</td>
                            <td>Pankaj Singh (Bangalore 89) <br/> (One For All - All For One)</td>
                            <td>42nd</td>
                            <td>Cruise</td>
                            <td>Vivek Mata <br/> (High Tide)</td>
                        </tr>
                        <tr>
                            <td>2022-2023</td>
                            <td>B K Singh Deo (Bhubaneswar 37) <br/> (Friends For Life)</td>
                            <td>43rd</td>
                            <td>Kolkata</td>
                            <td>Raja Shah <br/> (Khela Hobe)</td>
                        </tr>
                        <tr>
                            <td>2023-2024</td>
                            <td>Srinivasu Saraswatula (Mumbai 213) <br/> (Together Forever)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </Table>

                </Container>
            </div>
        );
    }
}

function HeaderCarousel() {
    let items = [
        {
            src: "https://assets.41clubsofindia.org/history/image1.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/history/image2.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/history/image3.jpg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
    ]
    return (
        <Carousel>
            {items.map((item, idx) => {
                return (
                    <Carousel.Item interval={1000} key={idx}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt}
                            style={{height: '600px', margin: 'auto', aspectRatio: '1', objectFit: 'cover'}}
                        />
                        <Carousel.Caption>
                            <h3>{item.title}</h3>
                            <p>{item.subtitle}</p>
                            {item.slug && <Button variant={'outline-secondary'} as={Link} to={item.slug}>Know More</Button>}
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}