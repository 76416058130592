import React from "react";

export default class Ticker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            num: 0,
            maxNum: props.number
        }
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000 / this.state.maxNum
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        if (this.state.num >= this.state.maxNum) {
            clearInterval(this.timerID);
            this.setState({
                ...this.state,
                plus: this.props.plus
            })
            return;
        }
        if (this.props.large && this.state.num / 10 < (this.state.maxNum / 10 - 1)) {
            this.setState({
                num: this.state.num + 10
            });
        } else {
            this.setState({
                num: this.state.num + 1
            });
        }
    }

    render() {
        return (
            <span>
                {this.state.num}{this.state.plus ? '+' : ''}
            </span>
        );
    }
}