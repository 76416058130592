import React from "react";

export default class HeroImage extends React.Component {
    render() {
        let url = "url(\"" + this.props.image + '")'
        return (
            <div style={{minHeight: '404px',
                position: 'relative',
                background: {url},
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }}></div>
        );
    }
}