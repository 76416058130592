import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Seo from "../Components/Seo";

export default class Contact extends React.Component {
    render() {
        return (
            <div>
                <Container>
                    <Seo title={'Contact Us | 41 India'}
                         description={'Contact 41 India\'s Secretariat'}
                         slug={'/contact'} />
                    <h1>Contact</h1>
                    <Row className='text-center'>
                        <Col>
                            <p>
                                <strong>Administrative Officer:</strong> Mrs Jyothi
                            </p>
                            <p>
                                41 India<br/>
                                Round Table House, 3rd Floor<br/>
                                80, Nungambakkam High Road<br/>
                                Chennai – 600 034
                            </p>
                            <p>
                                <strong>Landline:</strong> 044 28274539<br/>
                                <strong>Mobile:</strong> (+91) 7358673241<br/>
                                <strong>Email:</strong> office41india@gmail.com
                                {/*TODO: Email Address*/}
                            </p>
                        </Col>
                        <Col>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5592836786645!2d80.2417422154913!3d13.063701016383616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526668ade2eb13%3A0x2e4cc3a1a95fbff3!2sAssociation%20of%2041%20Clubs%20of%20India!5e0!3m2!1sen!2sin!4v1667567757527!5m2!1sen!2sin"
                                width="100%" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}