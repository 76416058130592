import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Seo from "../../Components/Seo";

export default class NationalBoard extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'National Board | 41 India'}
                     description={'Learn about the National Board and Leadership of 41 India, the pan India service organization.'}
                     slug={'/about/national-board'} />
                <div style={{
                    minHeight: '300px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/hero/national-board.jpeg")',
                    width: '100%',
                    margin: 'auto',
                    backgroundSize: 'cover'
                }}></div>
                <Container>
                    <Row>
                        <h1>National Board of 41 India</h1>
                    </Row>
                    <Row>
                        {boardMembers.map((boardMember, idx) => {
                            return (
                                <Col md={3} sm={4} key={idx}>
                                    <div>
                                        <img src={boardMember['image']} style={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: '1',
                                            objectFit: 'scale-down'
                                        }}/>
                                    </div>
                                    <div className='text-center'>
                                        <h4>{boardMember['name']}</h4>
                                        <p>
                                            {boardMember['designation']}<br/>
                                            <strong>Club:</strong> {boardMember['club']} | <strong>Area:</strong> {boardMember['area']}<br/>
                                            {boardMember['phone'] &&
                                            <span><strong>Phone:</strong> {boardMember['phone']}<br/></span>}
                                            {boardMember['email'] &&
                                            <span><strong>Email:</strong> {boardMember['email']}</span>}
                                        </p>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}

const boardMembers = [
    {
        "designation": "PRESIDENT",
        "name": "PANKUJ VATS",
        "area": 5,
        "club": 190,
        "email": "pankujvats@gmail.com",
        "phone": 9810041593,
        "image": "https://assets.41clubsofindia.org/national-board/2024/pankuj-vats.jpg"
    },
    {
        "designation": "IPP",
        "name": "SRINIVASU SARASWATULA",
        "area": 4,
        "club": 213,
        "email": "srinirti@gmail.com",
        "phone": 9820346931,
        "image": "https://assets.41clubsofindia.org/national-board/2024/srinivasu-saraswatula.jpg"
    },
    {
        "designation": "VP",
        "name": "BHUPINDER SINGH POMPY",
        "area": 6,
        "club": 160,
        "email": "pompy.singh@rediffmail.com",
        "phone": 9437021148,
        "image": "https://assets.41clubsofindia.org/national-board/2024/bhupinder-pompy.jpg"
    },
    {
        "designation": "SECRETARY",
        "name": "VIVEK MATA",
        "area": 3,
        "club": 89,
        "email": "vmata@charnock.biz",
        "phone": 9845014848,
        "image": "https://assets.41clubsofindia.org/national-board/2024/vivek-mata.jpg"
    },
    {
        "designation": "TREASURER",
        "name": "KIRAN HEBSUR",
        "area": 10,
        "club": 124,
        "email": "khebsur@gmail.com",
        "phone": 9972098121,
        "image": "https://assets.41clubsofindia.org/national-board/2024/kiran-hebsur.jpg"
    },
    {
        "designation": "IRO",
        "name": "GIRI Y V",
        "area": 7,
        "club": 150,
        "email": "giri@corpone.co.in",
        "phone": 9849004060,
        "image": "https://assets.41clubsofindia.org/national-board/2024/giri-yv.jpg"
    },
    {
        "designation": "EDITOR",
        "name": "YOGESH SHETH",
        "area": 3,
        "club": 98,
        "email": "jaysilks@gmail.com",
        "phone": 9844113750,
        "image": "https://assets.41clubsofindia.org/national-board/2024/yogesh-sheth.jpg"
    },
    {
        "designation": "PROJECTS",
        "name": "R GIRISH",
        "area": 3,
        "club": 116,
        "email": "girish_ramanathan@yahoo.co.in",
        "phone": 9980147591,
        "image": "https://assets.41clubsofindia.org/national-board/2024/r-girish.jpg"
    },
    {
        "designation": "FUND RAISING & CORP ALLIANCE",
        "name": "ANIL KUMAR AGARWAL",
        "area": 5,
        "club": 136,
        "email": "anil@buying-agents.com",
        "phone": 9811030926,
        "image": "https://assets.41clubsofindia.org/national-board/2024/anil-kumar-agarwal.jpg"
    },
    {
        "designation": "SECRETARIAT",
        "name": "GANESH KINI T.",
        "area": 2,
        "club": 7,
        "email": "tgkini@gmail.com",
        "phone": 9848126940,
        "image": "https://assets.41clubsofindia.org/national-board/2024/ganesh-kini.jpg"
    },
    {
        "designation": "RULES",
        "name": "DEEPAK BAJAJ",
        "area": 9,
        "club": 92,
        "email": "deebajaj@yahoo.com",
        "phone": 9412247478,
        "image": "https://assets.41clubsofindia.org/national-board/2024/deepak-bajaj.jpg"
    },
    {
        "designation": "SUPPLY HOUSE",
        "name": "SAMIIR JAIIN",
        "area": 5,
        "club": 273,
        "email": "sameirjain@gmail.com",
        "phone": 9910997965,
        "image": "https://assets.41clubsofindia.org/national-board/2024/samiir-jaiin.jpg"
    },
    {
        "designation": "HOWZATT",
        "name": "VINAY GRANDHI",
        "area": 2,
        "club": 236,
        "email": "vinay.grandhi@gmail.com",
        "phone": 9841032206,
        "image": "https://assets.41clubsofindia.org/national-board/2024/vinay-grandhi.jpg"
    },
    {
        "designation": "FAMIT",
        "name": "SHIVAKUMAR B.H.",
        "area": 10,
        "club": 227,
        "email": "shiva.soham2008@gmail.com",
        "phone": 9448375866,
        "image": "https://assets.41clubsofindia.org/national-board/2024/shivakumar-bh.jpg"
    },
    {
        "designation": "RTI CONNECT",
        "name": "LALIT GARG",
        "area": 5,
        "club": 190,
        "email": "lglalitgarg@gmail.com",
        "phone": 9717100101,
        "image": "https://assets.41clubsofindia.org/national-board/2024/lalit-garg.jpg"
    },
    {
        "designation": "EXTENSION",
        "name": "BHARATEESHA HONALI",
        "area": 3,
        "club": 173,
        "email": "me.a3chairman@gmail.com",
        "phone": 9342105541,
        "image": "https://assets.41clubsofindia.org/national-board/2024/bharateesha-honali.jpg"
    },
    {
        "designation": "YAP NORTH",
        "name": "SURESH AGARWAL",
        "area": 5,
        "club": 278,
        "email": "anantinox@gmail.com",
        "phone": 9811091806,
        "image": "https://assets.41clubsofindia.org/national-board/2024/suresh-agarwal.jpg"
    },
    {
        "designation": "YAP SOUTH",
        "name": "RAJESH KUMAR Y R",
        "area": 3,
        "club": 156,
        "email": "rajesh.yr@gmail.com",
        "phone": 9845115566,
        "image": "https://assets.41clubsofindia.org/national-board/2024/rajesh-kumar-yr.jpg"
    },
    {
        "designation": "SPL EVENTS & IMAGE BUILDING",
        "name": "PADMASHISH PRASAD",
        "area": 2,
        "club": 73,
        "email": "pbprasad62@yahoo.com",
        "phone": 9176510330,
        "image": "https://assets.41clubsofindia.org/national-board/2024/padmashish-prasad.jpg"
    },
    {
        "designation": "NAGM",
        "name": "AJIT KHULLAR",
        "area": 5,
        "club": 5,
        "email": "ajitkhullar@siinterpack.com",
        "phone": 9810111659,
        "image": "https://assets.41clubsofindia.org/national-board/2024/ajit-khullar.jpg"
    },
    {
        "designation": "WEB MASTER",
        "name": "M. ALEEM",
        "area": 3,
        "club": 89,
        "email": "41aleem@gmail.com",
        "phone": 9844012747,
        "image": "https://assets.41clubsofindia.org/national-board/2024/m-aleem.jpg"
    },
    {
        "designation": "FELLOWSHIP",
        "name": "PRAVEEN GOEL",
        "area": 7,
        "club": 121,
        "email": "41erpraveengoel@gmail.com",
        "phone": 9866655123,
        "image": "https://assets.41clubsofindia.org/national-board/2024/praveen-goel.jpg"
    },
    {
        "designation": "SPL INVITEE",
        "name": "BIKRAM KESARI SINGH DEO",
        "area": 6,
        "club": 37,
        "email": "bksinghdeo@gmail.com",
        "phone": 9337125371,
        "image": "https://assets.41clubsofindia.org/national-board/2024/bikram-kesari-singh-deo.jpg"
    },
    {
        "designation": "SPL INVITEE HYM CONV",
        "name": "GOPAKUMAR T.",
        "area": 1,
        "club": 129,
        "email": "gopakumart_ca@yahoo.com",
        "phone": 9496995955,
        "image": "https://assets.41clubsofindia.org/national-board/2024/gopakumar-t.jpg"
    },
    {
        "designation": "SPL INVITEE INTL VP",
        "name": "DR. SIDDHARTHAN",
        "area": 1,
        "club": 32,
        "email": "seehappy@gmail.com",
        "phone": 9994906090,
        "image": "https://assets.41clubsofindia.org/national-board/2024/siddharthan.jpg"
    },
    {
        "designation": "AC1",
        "name": "SENNIAN GIRI V R R",
        "area": 1,
        "club": 63,
        "email": "giri1970@yahoo.co.in",
        "phone": 9994307722,
        "image": "https://assets.41clubsofindia.org/national-board/2024/sennian-giri.jpg"
    },
    {
        "designation": "AC2",
        "name": "APOORVA MODI",
        "area": 2,
        "club": 97,
        "email": "aapoorvamodi@gmail.com",
        "phone": 9380585858,
        "image": "https://assets.41clubsofindia.org/national-board/2024/apoorva-modi.jpg"
    },
    {
        "designation": "AC3",
        "name": "KUTTUB NACEEM",
        "area": 3,
        "club": 89,
        "email": "kuttubnaceem@gmail.com",
        "phone": 9845040123,
        "image": "https://assets.41clubsofindia.org/national-board/2024/kuttub-naceem.jpg"
    },
    {
        "designation": "AC4",
        "name": "SUMEET KATARIYA",
        "area": 4,
        "club": 199,
        "email": "sum23in@yahoo.com",
        "phone": 9827159000,
        "image": "https://assets.41clubsofindia.org/national-board/2024/sumeet-katariya.jpg"
    },
    {
        "designation": "AC5",
        "name": "ANJUM ARORA",
        "area": 5,
        "club": 243,
        "email": "anjumritu@gmail.com",
        "phone": 9811267799,
        "image": "https://assets.41clubsofindia.org/national-board/2024/anjum-arora.jpg"
    },
    {
        "designation": "AC6",
        "name": "SUNDEEP KAPOOR",
        "area": 6,
        "club": 272,
        "email": "sundeepkapoor25@gmail.com",
        "phone": 9830071430,
        "image": "https://assets.41clubsofindia.org/national-board/2024/sundeep-kapoor.jpg"
    },
    {
        "designation": "AC7",
        "name": "RAGHU RAM VYAS",
        "area": 7,
        "club": 51,
        "email": "rrvyas1970@gmail.com",
        "phone": 8100030075,
        "image": "https://assets.41clubsofindia.org/national-board/2024/raghu-ram-vyas.jpg"
    },
    {
        "designation": "AC8",
        "name": "P MANOJ KUMAR",
        "area": 8,
        "club": 153,
        "email": "pmanojkumar07@gmail.com",
        "phone": 9249511000,
        "image": "https://assets.41clubsofindia.org/national-board/2024/p-manoj-kumar.jpg"
    },
    {
        "designation": "AC9",
        "name": "BHUPINDER SINGH",
        "area": 9,
        "club": 64,
        "email": "chandhok-bs@hotmail.com",
        "phone": 9696666777,
        "image": "https://assets.41clubsofindia.org/national-board/2024/bhupinder-singh.jpg"
    },
    {
        "designation": "AC10",
        "name": "DILIP CHANDAK",
        "area": 10,
        "club": 125,
        "email": "dilip@vegaauto.com",
        "phone": 9845353534,
        "image": "https://assets.41clubsofindia.org/national-board/2024/dilip-chandak.jpg"
    }
]