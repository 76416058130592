import React from "react";
import PublicationViewer from "../../Components/PublicationViewer";
import Seo from "../../Components/Seo";

export default class FellowshipCommunication extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Fellowship Magazine | 41 India'}
                     description={'View the current and past editions of the Fellowship Magazine of 41 India'}
                     slug={'/communication/fellowship'} />
                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/communication/header.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
                <PublicationViewer obj={obj}/>
            </div>
        );
    }
}

let obj = {
    header: 'Fellowship',
    data: [
        {
            title: '',
            issues: [
                {
                    title: 'October 2020',
                    link: 'https://assets.41clubsofindia.org/communication/fellowship-oct2020.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/fellowship-oct2020.jpg',
                },
                {
                    title: 'September 2019',
                    link: 'https://assets.41clubsofindia.org/communication/fellowship-sept2019.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/fellowship-sept2019.jpg',
                },
            ]
        }
    ]
}

