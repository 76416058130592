import React from "react";
import CustomForm from "../../Components/CustomForm";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

export default class ClubCharter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const fields = [
            { name: "newClubName", label: "Proposed New Club Name", type: 'input', required: true },
            { name: "cityState", label: "City / State", type: 'input', required: true },
            { name: "area", label: "41 Area", type: 'input', required: true },
            { name: "charterDate", label: "Proposed date of Club Charter", type: 'date', required: true },
            { name: "sponsorClubNumber", label: "Sponsor Club Number / Name", type: 'input', required: true },
            { name: "sponsorChairman", label: "Sponsor Club Chairman Name & WhatsApp Contact", type: 'input', required: true },
            { name: "areaChairmanName", label: "Area Chairman Name", type: 'input', required: true },
            { name: "proposedChairman", label: "Proposed Club Chairman Name & WhatsApp number", type: 'input', required: true },
            { name: "proposedSecretary", label: "Proposed Club Secretary Name", type: 'input', required: true },
            { name: "proposedContactMember", label: "Proposed Club Contact Member Name", type: 'input', required: true },
            { name: "numberOfNewMembers", label: "Number of New Members in Proposed Club", type: 'input', required: true },
            { name: "numberOfTransferredMembers", label: "Number of Transfer Members in Proposed Club", type: 'input', required: true },
            { name: "totalMembers", label: "Total Number of Members in Proposed Club", type: 'input', required: true },
            { name: "membersList", label: "Name list of members", type: 'multiline', required: true },
            { name: "paymentMethod", label: "Payment Method", type: 'select', selectOptions: ['NEFT/RTGS', 'Cheque', 'Demand Draft'], required: true },
            { name: "paymentDate", label: "Payment Date", type: 'date', required: true },
            { name: "paymentNumber", label: "Payment Transaction Number (UTR/Cheque/DD Number)", type: 'input', required: true },
            { name: "addlPaymentInfo", label: "In case of multiple payments, give details of all payments here", type: 'multiline', required: false },
            { name: "signatures", label: "Signatures of Proposed Club Chairman, Sponsor Club Chairman and Area Chairman (Upload Declarations and Signatures)", type: 'file', required: true },
        ];


        return (
            <Container>
                <h1>Club Charter Form</h1>
                <CustomForm fields={fields} onSubmit={(data) => this.console.log(data)}/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Info</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Signatures of Proposed Club Chairman, Sponsor Club Chairman and Area Chairman</td>
                        <td>I confirm my approval for the creation of this club</td>
                    </tr>
                    <tr>
                        <td>Payment Amount into Account 1</td>
                        <td>Rs. 5000 /-</td>
                    </tr>
                    <tr>
                        <td>Bank Account Information - Account 1</td>
                        <td>
                            In favour of: association of 41 clubs of India<br/>
                            Bank Name: Axis Bank Ltd<br/>
                            Account No: 914010042622856<br/>
                            Branch Name: Sterling Road, Chennai - 600034<br/>
                            IFS Code:UTIB0002018
                        </td>
                    </tr>
                    <tr>
                        <td>Payment Amount into Account 2</td>
                        <td>Rs. 5,000/- Per TRANSFER Member ( if any)<br/>
                            Rs.12500/- Per new member<br/>
                            Rs.5800/- for  Jewels n Name bars and Admin </td>
                    </tr>
                    <tr>
                        <td>Bank Account Information - Account 2</td>
                        <td>
                            In favour of: 41 India<br/>
                            Bank Name: Axis Bank Ltd<br/>
                            Account No: 920020048319350<br/>
                            Branch Name: Sterling Road, Chennai - 600034<br/>
                            IFS Code:UTIB0002018
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        );
    }
}