import React from "react";
import Seo from "../Components/Seo";
import Container from "react-bootstrap/Container";

export default class PageNotFound extends React.Component {
    render() {
        return (
            <Container className={'text-center'}>
                <Seo title={'Page Not Found | 41 India'}
                     slug={'/404'} />
                <h3>Page Not Found</h3>
                <p>Oops, we're not sure how you got here. Please use our navigation bar at the top of the screen to find the page you were looking for.</p>
            </Container>
        );
    }
}