import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";

export default class ActiveCities extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Active Cities | 41 India'}
                     description={'Learn about 41 India, the pan India service organization, and their presence across the country'}
                     slug={'/about/active-cities'} />

                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/hero/active-cities.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
            <Container className={'text-center'}>
                <h1>Active Cities for 41 India</h1>
                <img src={'https://assets.41clubsofindia.org/map.jpg'} width={'90%'}/>
            </Container>
            </div>
        );
    }
}