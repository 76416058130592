import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link, Redirect} from "react-router-dom";
import './Navigation.css';
// import {AiFillFacebook, FaFacebookF} from "react-icons/all";
import {FaFacebookF, FaTwitter, FaUser, FaYoutube} from "react-icons/fa";
import ExternalLink from "../ExternalLink";

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'about-us': false,
            programs: false,
            projects: false,
            communication: false,
            archives: false,
        }
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter(name) {
        this.setState({
            ...this.state,
            'about-us': false,
            programs: false,
            projects: false,
            communication: false,
            archives: false,
            [name]: true
        })
    }

    onMouseLeave(name) {
        this.setState({
            ...this.state,
            'about-us': false,
            programs: false,
            projects: false,
            communication: false,
            archives: false,
            [name]: false
        })
    }

    render() {
        if (this.state.reload) {
            return (
                <Redirect to={this.state.reload}/>
            )
        }

        return (
            <div>
                {/*<Navbar bg="light" variant="light">*/}
                {/*    <Container>*/}
                {/*        <Navbar.Brand href="/">*/}
                {/*            <img*/}
                {/*                alt=""*/}
                {/*                src="/assets/logo.jpg"*/}
                {/*                width="30"*/}
                {/*                height="30"*/}
                {/*                className="d-inline-block align-top"*/}
                {/*            />{' '}*/}
                {/*            41 INDIA*/}
                {/*        </Navbar.Brand>*/}
                {/*        <Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
                {/*        <Navbar.Collapse id="basic-navbar-nav">*/}
                {/*            <Nav className="me-auto">*/}
                {/*                <NavDropdown title="About Us" id="about-us-nav-dropdown"*/}
                {/*                             // show={this.state['about-us']}*/}
                {/*                             // onMouseEnter={() => this.onMouseEnter('about-us')}*/}
                {/*                             // onMouseLeave={() => this.onMouseLeave('about-us')}*/}
                {/*                >*/}
                {/*                    <NavDropdown.Item as={Link} to="/about">About</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/about/history">History</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/about/national-board">National*/}
                {/*                        Board</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/about/roll-of-honour">Roll of*/}
                {/*                        Honour</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/about/active-cities">Active*/}
                {/*                        Cities</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link}*/}
                {/*                                      to="/about/international">International</NavDropdown.Item>*/}
                {/*                </NavDropdown>*/}
                {/*                <NavDropdown title="Programs" id="programs-nav-dropdown"*/}
                {/*                             // show={this.state.programs}*/}
                {/*                             // onMouseEnter={() => this.onMouseEnter('programs')}*/}
                {/*                             // onMouseLeave={() => this.onMouseLeave('programs')}*/}
                {/*                >*/}
                {/*                    <NavDropdown.Item as={Link} to="/programs/yap">Young Ambassador*/}
                {/*                        Program</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/programs/yip">Young Internship*/}
                {/*                        Program</NavDropdown.Item>*/}
                {/*                </NavDropdown>*/}
                {/*                <NavDropdown title="Projects" id="projects-nav-dropdown"*/}
                {/*                             // show={this.state.projects}*/}
                {/*                             // onMouseEnter={() => this.onMouseEnter('projects')}*/}
                {/*                             // onMouseLeave={() => this.onMouseLeave('projects')}*/}
                {/*                >*/}
                {/*                    <NavDropdown.Item as={Link} to="/projects/syj">Share Your Joy</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/projects/ftde">Freedom Through Digital*/}
                {/*                        Education</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/projects/others">Young Internship*/}
                {/*                        Program</NavDropdown.Item>*/}
                {/*                </NavDropdown>*/}
                {/*                <NavDropdown title="Communication" id="communication-nav-dropdown"*/}
                {/*                             // show={this.state.communication}*/}
                {/*                             // onMouseEnter={() => this.onMouseEnter('communication')}*/}
                {/*                             // onMouseLeave={() => this.onMouseLeave('communication')}*/}
                {/*                >*/}
                {/*                    <NavDropdown.Item as={Link} to="/communication/selfie">Selfie</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link}*/}
                {/*                                      to="/communication/fellowship">Fellowship</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link}*/}
                {/*                                      to="/communication/happenings">Happenings</NavDropdown.Item>*/}
                {/*                </NavDropdown>*/}
                {/*                <NavDropdown title="Archives" id="archives-nav-dropdown"*/}
                {/*                             // show={this.state.archives}*/}
                {/*                             // onMouseEnter={() => this.onMouseEnter('archives')}*/}
                {/*                             // onMouseLeave={() => this.onMouseLeave('archives')}*/}
                {/*                >*/}
                {/*                    <NavDropdown.Item as={Link} to="/archives/selfie">Selfie</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link}*/}
                {/*                                      to="/archives/fellowship">Fellowship</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link}*/}
                {/*                                      to="/archives/happenings">Happenings</NavDropdown.Item>*/}
                {/*                    <NavDropdown.Item as={Link} to="/archives/others">Others</NavDropdown.Item>*/}
                {/*                </NavDropdown>*/}
                {/*                <Nav.Link as={Link} to="/events">Events</Nav.Link>*/}
                {/*                <Nav.Link as={Link} to="/contact">Contact</Nav.Link>*/}
                {/*            </Nav>*/}
                {/*        </Navbar.Collapse>*/}
                {/*    </Container>*/}
                {/*</Navbar>*/}
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src="https://assets.41clubsofindia.org/logo.jpg"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            41 INDIA
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavDropdown title="About Us" id="about-us-nav-dropdown"
                                             show={this.state['about-us']}
                                             onMouseEnter={() => this.onMouseEnter('about-us')}
                                             onMouseLeave={() => this.onMouseLeave('about-us')}
                                >
                                    <NavDropdown.Item as={Link} to="/about">About</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/about/history">History</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/about/national-board">National
                                        Board</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/about/roll-of-honour">Roll of
                                        Honour</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/about/active-cities">Active
                                        Cities</NavDropdown.Item>
                                    {/*<NavDropdown.Item as={Link}*/}
                                    {/*                  to="/about/international">International</NavDropdown.Item>*/}
                                    {/*<NavDropdown.Item as={ExternalLink}*/}
                                    {/*                  href="https://www.41international.net">International</NavDropdown.Item>*/}
                                </NavDropdown>
                                <NavDropdown title="Programs" id="programs-nav-dropdown"
                                             show={this.state.programs}
                                             onMouseEnter={() => this.onMouseEnter('programs')}
                                             onMouseLeave={() => this.onMouseLeave('programs')}
                                >
                                    <NavDropdown.Item as={Link} to="/programs/yap">Young Ambassador
                                        Program</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/programs/yip">Young Internship
                                        Program</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Projects" id="projects-nav-dropdown"
                                             show={this.state.projects}
                                             onMouseEnter={() => this.onMouseEnter('projects')}
                                             onMouseLeave={() => this.onMouseLeave('projects')}
                                >
                                    <NavDropdown.Item as={Link} to="/projects/syj">Share Your Joy</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/projects/ftde">Freedom Through Digital
                                        Education</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/projects/others">Other Projects</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Communication" id="communication-nav-dropdown"
                                             show={this.state.communication}
                                             onMouseEnter={() => this.onMouseEnter('communication')}
                                             onMouseLeave={() => this.onMouseLeave('communication')}
                                >
                                    <NavDropdown.Item as={Link} to="/communication/selfie">Selfie</NavDropdown.Item>
                                    <NavDropdown.Item as={Link}
                                                      to="/communication/fellowship">Fellowship</NavDropdown.Item>
                                    <NavDropdown.Item as={Link}
                                                      to="/communication/happenings">Happenings</NavDropdown.Item>
                                    <NavDropdown.Item as={Link}
                                                      to="/communication/others">Others</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Links" id="links-nav-dropdown"
                                    show={this.state.links}
                                    onMouseEnter={() => this.onMouseEnter('links')}
                                    onMouseLeave={() => this.onMouseLeave('links')}
                                >
                                    <ExternalLink className='dropdown-item' href='https://www.41international.net/'>41 International</ExternalLink>
                                    <ExternalLink className='dropdown-item' href='https://www.tangentindia.org/'>Tangent India</ExternalLink>
                                    <ExternalLink className='dropdown-item' href='https://www.roundtableindia.org/'>Round Table India</ExternalLink>
                                    <ExternalLink className='dropdown-item' href='https://www.ladiescircleindia.org/'>Ladies Circle India</ExternalLink>
                                    <ExternalLink className='dropdown-item' href='https://www.41bnb.com/'>41 BnB</ExternalLink>
                                </NavDropdown>
                                {/*<NavDropdown title="Archives" id="archives-nav-dropdown"*/}
                                {/*    show={this.state.archives}*/}
                                {/*    onMouseEnter={() => this.onMouseEnter('archives')}*/}
                                {/*    onMouseLeave={() => this.onMouseLeave('archives')}*/}
                                {/*>*/}
                                {/*    <NavDropdown.Item as={Link} to="/archives/selfie">Selfie</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item as={Link}*/}
                                {/*                      to="/archives/fellowship">Fellowship</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item as={Link}*/}
                                {/*                      to="/archives/happenings">Happenings</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item as={Link} to="/archives/others">Others</NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                                <Nav.Link as={Link} to="/events">Events</Nav.Link>
                                <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                                <Nav.Link as={Link} to="/donate">Donate</Nav.Link>
                                <ExternalLink className='nav-link' href='https://directory.41clubsofindia.org/'><FaUser size={'1.5em'}/> Directory</ExternalLink>
                            </Nav>
                            <div className="d-flex">
                                <a target="_blank" rel="noreferrer nofollow noorigin"
                                   href={'https://www.facebook.com/41ClubsOfIndia/'}
                                   style={{color: "black", paddingRight: '15%'}}><FaFacebookF/></a>
                                <a target="_blank" rel="noreferrer nofollow noorigin"
                                   href={'https://www.twitter.com/41ersClub/'}
                                   style={{color: "black", paddingRight: '15%'}}><FaTwitter/></a>
                                <a target="_blank" rel="noreferrer nofollow noorigin"
                                   href={'https://www.youtube.com/c/41clubsindia'} style={{color: "black"}}><FaYoutube/></a>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}