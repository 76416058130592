import React from "react";

export default class ExternalLink extends React.Component {
    render() {
        return (
            <span>
                <a className={this.props.className} href={this.props.href} target={"_blank"} rel={"noreferrer nofollow noopener noorigin"}>
                    {this.props.children}
                </a>
            </span>
        );
    }
}