import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";

export default class Yip extends React.Component {
    render() {
        return (
            <Container>
                <Seo title={'Young Internship Program | 41 India'}
                     description={'Learn more about the Young Internshi Program of 41 India, apply for a prestigious Internship'}
                     slug={'/programs/yip'} />
                <h1>Young Internship Program</h1>
                <h3>This page is coming soon!</h3>
            </Container>
        );
    }
}