import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Components/Seo";
import ProjectsCarousel from "./ProjectsCarousel";

export default class OtherProjects extends React.Component {
    render() {
        return (
            <div>
                <ProjectsCarousel />
                <Container>
                    <Seo title={'Other Projects | 41 India'}
                         description={'Learn more about various projects and initiatives of 41 India'}
                         slug={'/projects/others'} />
                    <h1>Other Projects</h1>
                    <h3>This page is coming soon!</h3>
                </Container>
            </div>
        );
    }
}