import React from "react";
import PublicationViewer from "../../Components/PublicationViewer";
import Seo from "../../Components/Seo";

export default class HappeningsCommunication extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Happenings Magazine | 41 India'}
                     description={'View the current and past editions of the Happenings Magazine of 41 India'}
                     slug={'/communication/happenings'} />
                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/communication/header.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
                <PublicationViewer obj={obj}/>
            </div>
        );
    }
}

let obj = {
    header: 'Happenings',
    data: [
        {
            title: '',
            issues: [
                {
                    title: 'June 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/happeing8.pdf'
                },
                {
                    title: 'May 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/happeing7.pdf'
                },
                {
                    title: 'April 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/happeing6.pdf'
                },
                {
                    title: 'March 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/happening5.pdf'
                },
                {
                    title: 'February 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/happening4.pdf'
                },
                {
                    title: 'January 2022',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_V.pdf'
                },
                {
                    title: 'December 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_IV.pdf'
                },
                {
                    title: 'November 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_III.pdf'
                },
                {
                    title: 'October 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_II.pdf'
                },
                {
                    title: 'September 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_SEPTEMBER_2021.pdf'
                },
                {
                    title: 'September 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_I.pdf'
                },
                {
                    title: 'June 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_JUNE_2021.pdf'
                },
                {
                    title: 'April 2021',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_APRIL_2021.pdf'
                },
                {
                    title: 'November 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_NOVEMBER_2020.pdf'
                },
                {
                    title: 'October 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_OCT_2020.pdf'
                },
                {
                    title: 'August 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_AUG_2020.pdf'
                },
                {
                    title: 'July 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_JULY_2020.pdf'
                },
                {
                    title: 'June 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_JUNE_2020.pdf'
                },
                {
                    title: 'May 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_MAY_2020.pdf'
                },
                {
                    title: 'April 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_APRIL_2020.pdf'
                },
                {
                    title: 'March 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_MARCH_2020_final.pdf'
                },
                {
                    title: 'February 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_FEBRUARY_2020.pdf'
                },
                {
                    title: 'January 2020',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_JANUARY_2020.pdf'
                },
                {
                    title: 'December 2019',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_DECEMBER_2019.pdf'
                },
                {
                    title: 'November 2019',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_NOVEMBER_2019.pdf'
                },
                {
                    title: 'October 2019',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_OCTOBER_2019.pdf'
                },
                {
                    title: 'September 2019',
                    link: 'https://assets.41clubsofindia.org/communication/happenings/HAPPENINGS_SEPTEMBER_2019.pdf'
                },
            ]
        }
    ]
}

