import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default function ProjectsCarousel() {
    let items = [
        {
            src: "https://assets.41clubsofindia.org/projects/project1.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/projects/project2.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/projects/project3.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/projects/project4.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
        {
            src: "https://assets.41clubsofindia.org/projects/project5.jpeg",
            alt: "",
            title: '',
            subtitle: '',
            slug: ''
        },
    ]
    return (
        <Carousel>
            {items.map((item, idx) => {
                return (
                    <Carousel.Item interval={1000} key={idx}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt}
                            style={{height: '600px', margin: 'auto', aspectRatio: '1', objectFit: 'cover'}}
                        />
                        <Carousel.Caption>
                            <h3>{item.title}</h3>
                            <p>{item.subtitle}</p>
                            {item.slug && <Button variant={'outline-secondary'} as={Link} to={item.slug}>Know More</Button>}
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}