import React from "react";
import CustomForm from "../../Components/CustomForm";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

export default class MemberTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const fields = [
            { name: "memberName", label: "Member's Name", type: 'input', required: true },
            { name: "currentClubInfo", label: "Current Club Number / Name", type: 'input', required: true },
            { name: "proposedTransferClubInfo", label: "Proposed Transfer Club Number / Name", type: 'input', required: true },
            { name: "officeAddress", label: "Office Address", type: 'multiline', required: true },
            { name: "residentialAddress", label: "Residential Address", type: 'multiline', required: true },
            { name: "telephoneNumberRes", label: "Residential Telephone Number & WhatsApp number", type: 'input', required: true },
            { name: "telephoneNumberOff", label: "Office Telephone Number & WhatsApp number", type: 'input', required: true },
            { name: "mobileNumber", label: "Mobile Number", type: 'input', required: true },
            { name: "email", label: "Email Address", type: 'email', required: true },
            { name: "paymentMethod", label: "Payment Method", type: 'select', selectOptions: ['NEFT/RTGS', 'Cheque', 'Demand Draft'], required: true },
            { name: "paymentDate", label: "Payment Date", type: 'date', required: true },
            { name: "paymentNumber", label: "Payment Transaction Number (UTR/Cheque/DD Number)", type: 'input', required: true },
            { name: "signatures", label: "Signatures of Current Club Chairman and New Club Chairman (Upload Declarations and Signatures)", type: 'file', required: true },
        ];

        return (
            <Container>
                <h1>Member Transfer Form</h1>
                <CustomForm fields={fields} onSubmit={(data) => this.console.log(data)}/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Info</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Declaration by Current Club Chairman / Secretary:</td>
                            <td>I confirm that I have no objection to above transfer and the member has cleared all his dues to our Club</td>
                        </tr>
                    <tr>
                        <td>
                            Declaration by New Club Chairman / Secretary:
                        </td>
                        <td>
                            I confirm that I have no objection to above transfer and willing to accept the above 41er as a member of our Club
                        </td>
                    </tr>
                    <tr>
                        <td>Payment Amount</td>
                        <td>5000</td>
                    </tr>
                    <tr>
                        <td>Bank Account Information</td>
                        <td>
Payment Bank Details : 41 INDIA<br/>
Bank Name: Axis Bank Ltd<br/>
Account No: 920020048319350<br/>
Branch Name: Sterling Road, Chennai - 600034<br/>
IFS Code:UTIB0002018
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        );
    }
}