import React from "react";
import Container from "react-bootstrap/Container";
import Loading from "../../Components/Loading";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";

export default class YapForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toc: false,
            validated: false,
            // validation: {},
            travelledWithYap: true,
            preferredTour: this.props.datesData[0].title,
            smoker: true,
            drinker: true,
            vegetarian: true
        }
        this.changeState = this.changeState.bind(this);
        this.changeChecked = this.changeChecked.bind(this);
        this.uploadPassport = this.uploadPassport.bind(this);
        this.checkValidity = this.checkValidity.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        // let validationResponse = e.target.value.length > 2 ? true : false;
        // if (e.target.name === 'mobile' || e.target.name === 'fatherMobile') {
        //     validationResponse = e.target.value.length === 10;
        // }
        // if (e.target.name === 'email' || e.target.name === 'fatherEmail') {
        //     var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        //     validationResponse = e.target.value.match(validRegex);
        // }
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            // validation: {
            //     ...this.state.validation,
            //     [e.target.name]: validationResponse
            // }
        })
        this.checkValidity();
    }

    changeChecked(e) {
        // TODO: Make this.state.toc hold the timestamp of when it was agreed to
        this.setState({
            ...this.state,
            [e.target.name]: !this.state[e.target.name]
        })
    }

    uploadPassport(e) {
        this.setState({
            ...this.state,
            file: e.target.files[0],
            files: e.target.files
        })
    }

    checkValidity() {

    }

    submit(event) {
        if (this.state.submissionComplete) {
            return;
        }
        // TODO: Figure this out
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            ...this.state,
            validated: true
        })

        if (this.checkValidity() === false) {
            return;
        }

        this.setState({
            ...this.state,
            submissionInProgress: true
        })

        // let file = this.state.file;
        // let fileName = 'yap-submission-' + Date.now() + '.pdf';
        // fetch('https://b1tpmz82h0.execute-api.ap-south-1.amazonaws.com/yap-passport-upload?contentType=' + file.type + '&fileName=' + fileName)
        //     .then(resp => resp.text())
        //     .then(uploadUrl => {
        //         console.log(uploadUrl)
        //         return fetch(uploadUrl, {
        //             method: 'PUT',
        //             // headers: new Headers({ 'Content-Type': file.type}),
        //             body: file
        //         })
        //             .then(response => response.text())
        //             .then(response => {
        //                 return fileName
        //             })
        //     })


        // // Step 1: Upload the Passport
        // // Step 2: Submit Data with passport info
        fetch('https://hth8094we5.execute-api.ap-south-1.amazonaws.com/yap-submission', {
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(resp => resp.text())
            .then(resp => {
                this.setState({
                    ...this.state,
                    submissionInProgress: false,
                    submissionComplete: true
                })
            })
        // console.log(JSON.stringify(this.state))
    }

    render() {
        return (
            <Container>
                {this.state.submissionInProgress && <Loading/>}
                {this.state.submissionComplete && <Alert variant={'success'}>Your submission has been received</Alert>}
                <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
                    <Row><h3>Personal Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter first name"
                                              value={this.state.firstName} onChange={this.changeState}
                                              name='firstName'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter last name"
                                              value={this.state.lastName} onChange={this.changeState} name='lastName'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="dob">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control required type="date" value={this.state.dob} onChange={this.changeState}
                                              name='dob'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="passport">
                                <Form.Label>Passport Number</Form.Label>
                                <Form.Control required type="text" placeholder="Enter passport number"
                                              value={this.state.passport} onChange={this.changeState} name='passport'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="nationality">
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control required type="text" placeholder="Enter nationality"
                                              value={this.state.nationality} onChange={this.changeState}
                                              name='nationality'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="gender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control required type="text" placeholder="Enter gender" value={this.state.gender}
                                              onChange={this.changeState} name='gender'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="motherTongue">
                                <Form.Label>Mother Tongue</Form.Label>
                                <Form.Control required type="text" placeholder="Enter mother tongue"
                                              value={this.state.motherTongue} onChange={this.changeState}
                                              name='motherTongue'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="mobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control required type="number" placeholder="Enter mobile"
                                              value={this.state.mobile} onChange={this.changeState} name='mobile'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" value={this.state.email}
                                              onChange={this.changeState} name='email'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="profession">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control required type="text" placeholder="Enter profession"
                                              value={this.state.profession} onChange={this.changeState}
                                              name='profession'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter address"
                                              value={this.state.address} onChange={this.changeState} name='address'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control required type="text" placeholder="Enter city"
                                              value={this.state.city} onChange={this.changeState}
                                              name='city'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="pincode">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control required type="text" placeholder="Enter pincode"
                                              value={this.state.pincode} onChange={this.changeState}
                                              name='pincode'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Father's Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherName">
                                <Form.Label>Father's Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter father's name"
                                              value={this.state.fatherName} onChange={this.changeState}
                                              name='fatherName'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherClub">
                                <Form.Label>Father's Club</Form.Label>
                                <Form.Control required type="text" placeholder="Enter father's club"
                                              value={this.state.fatherClub} onChange={this.changeState}
                                              name='fatherClub'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherMobile">
                                <Form.Label>Father's Mobile</Form.Label>
                                <Form.Control required type="number" placeholder="Enter father's mobile"
                                              value={this.state.fatherMobile} onChange={this.changeState}
                                              name='fatherMobile'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherEmail">
                                <Form.Label>Father's Email</Form.Label>
                                <Form.Control required type="email" placeholder="Enter father's email"
                                              value={this.state.fatherEmail} onChange={this.changeState}
                                              name='fatherEmail'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Educational Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="educationHistory">
                                <Form.Label>Educational History</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter educational history"
                                              value={this.state.educationHistory} onChange={this.changeState}
                                              name='educationHistory'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="finalDegree">
                                <Form.Label>Final Degree</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter final degree"
                                              value={this.state.finalDegree} onChange={this.changeState}
                                              name='finalDegree'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Other Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="travelledWithYap">
                                <Form.Label>Have you ever travelled on a YAPS tour?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='travelledWithYap'
                                             value={this.state.travelledWithYap}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="listOfCountriesTravelled">
                                <Form.Label>Which countries have you travelled to? (with and without YAPS)</Form.Label>
                                <Form.Control required type="text" placeholder="Which countries have you travelled to?"
                                              value={this.state.listOfCountriesTravelled} onChange={this.changeState}
                                              name='listOfCountriesTravelled'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="listOfCountriesTravelled">
                                <Form.Label>Which YAP Tour are you interested in?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='preferredTour'
                                             value={this.state.preferredTour}>
                                    {this.props.datesData.map((dates, idx) => {
                                        return (
                                            <option value={dates.title} key={idx}>{dates.title}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="sportsActivities">
                                <Form.Label>Do you participate in any sports or other activities?</Form.Label>
                                <Form.Control required as="textarea" rows={3}
                                              placeholder="Do you participate in any sports or other activities?"
                                              value={this.state.sportsActivities} onChange={this.changeState}
                                              name='sportsActivities'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="medicalInfo">
                                <Form.Label>Do you have any diseases, allergies or medical information you wish to
                                    inform us about?</Form.Label>
                                <Form.Control required as="textarea" rows={3}
                                              placeholder="Do you have any diseases, allergies or medical information you wish to inform us about?"
                                              value={this.state.medicalInfo} onChange={this.changeState}
                                              name='medicalInfo'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="smoker">
                                <Form.Label>Are you a smoker?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='smoker'
                                             value={this.state.smoker}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="drinker">
                                <Form.Label>Are you a drinker?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='drinker'
                                             value={this.state.drinker}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="vegetarian">
                                <Form.Label>Are you a vegetarian?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='vegetarian'
                                             value={this.state.vegetarian}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="additionalInfo">
                                <Form.Label>Is there any additional information you wish to provide?</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                              placeholder="Is there any additional information you wish to provide?"
                                              value={this.state.additionalInfo} onChange={this.changeState}
                                              name='additionalInfo'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="passportUpload">
                                <Form.Label>Please upload a copy of your passport</Form.Label>
                                <Form.Control required type="file" onChange={this.uploadPassport} name='passportUpload'
                                              accept='.pdf'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Payment Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="utr">
                                <Form.Label>Please provide the UTR of your payment</Form.Label>
                                <Form.Control required type="text" placeholder="Enter transaction UTR"
                                              value={this.state.utr} onChange={this.changeState}
                                              name='utr'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="bank">
                                <Form.Label>Please provide the Bank from which your payment was made</Form.Label>
                                <Form.Control required type="text" placeholder="Enter bank from which payment was made"
                                              value={this.state.bank} onChange={this.changeState}
                                              name='bank'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="bankBranch">
                                <Form.Label>Please provide the Bank Branch from which your payment was made</Form.Label>
                                <Form.Control required type="text" placeholder="Enter bank branch from which payment was made"
                                              value={this.state.bankBranch} onChange={this.changeState}
                                              name='bankBranch'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="paymentDate">
                                <Form.Label>Payment Date</Form.Label>
                                <Form.Control required type="date"
                                              value={this.state.paymentDate} onChange={this.changeState}
                                              name='paymentDate'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <p>
                            <strong>Disclaimer</strong>
                        </p>
                        <ul>
                            <li>
                                So declare that I accept to participate in the YAP with the conditions so arranged
                                between the
                                "YAP / Famex Austauschverein e.V." and the sponsoring national association.
                            </li>
                            <li>
                                I shall make all arrangements such as return flight tickets.
                            </li>
                            <li>
                                I waive my rights to make any claims against the YAP/Famex –Austauschverein e.V., 41
                                International and the sponsoring Associations for misrepresentation and for damages of
                                any
                                nature either or that I might encounter during my participation of this program.
                            </li>
                            <li>
                                I shall take an insurance policy before my departure and for the duration of the program
                                to
                                cover myself against sickness and accident, in addition I shall take cover for Civil
                                liability for
                                material or Corporal damages incurred by myself.
                            </li>
                            <li>
                                I shall not indulge in drugs or any illicit activities. I understand that this would
                                result in
                                immediate deportation.
                            </li>
                            <li>
                                I understand that our family would be obligated to host inbound Yaps in the future .
                            </li>
                        </ul>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="toc">
                                <Form.Check required type="checkbox" name='toc' label="I agree to the terms"
                                            onClick={this.changeChecked}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.submissionInProgress && <Loading/>}
                    {this.state.submissionComplete && <Alert variant={'success'}>Your submission has been received</Alert>}
                    <Row>
                        <Col md>
                            <Button variant="primary" onClick={this.submit} disabled={this.state.submissionComplete}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }
}