import React from "react";
import PublicationViewer from "../../Components/PublicationViewer";
import HeroImage from "../../Components/HeroImage";
import Seo from "../../Components/Seo";

export default class OtherCommunication extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Other Magazines and Communication | 41 India'}
                     description={'View the current and past editions of various Magazines and Communications of 41 India'}
                     slug={'/communication/others'} />
                <div style={{minHeight: '404px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/communication/header.jpeg")',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                }}></div>
                <PublicationViewer obj={obj}/>
            </div>
        );
    }
}

let obj = {
    header: 'Other Communication',
    data: [
        {
            title: 'COVID Warriors Archives',
            issues: [
                {
                    title: 'Volume IV',
                    link: 'https://assets.41clubsofindia.org/communication/covidwarriors-iv.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/covidwarriors-iv.jpg'
                },
                {
                    title: 'Volume III',
                    link: 'https://assets.41clubsofindia.org/communication/covidwarriors-iii.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/covidwarriors-iii.jpg'
                },
                {
                    title: 'Volume II',
                    link: 'https://assets.41clubsofindia.org/communication/covidwarriors-ii.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/covidwarriors-ii.jpg'
                },
                {
                    title: 'Volume I',
                    link: 'https://assets.41clubsofindia.org/communication/covidwarriors-i.pdf',
                    image: 'https://assets.41clubsofindia.org/communication/covidwarriors-i.jpg'
                },
            ]
        }
    ]
}

