import React from "react";
import CustomForm from "../../Components/CustomForm";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

export default class NewMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const fields = [
            { name: "name", label: "Member's Name", type: 'input', required: true },
            { name: "dob", label: "Date of Birth (DDMMYYYY)", type: 'date', required: true },
            { name: "pan", label: "PAN Card No", type: 'input', required: true },
            { name: "doa", label: "Date of Anniversary", type: 'date', required: true },
            { name: "officeAddress", label: "Office Address", type: 'multiline', required: true },
            { name: "residentialAddress", label: "Residential Address", type: 'multiline', required: true },
            { name: "telephone", label: "Telephone Number", type: 'input', required: true },
            { name: "mobile", label: "Mobile Number", type: 'input', required: true },
            { name: "email", label: "Email Address", type: 'email', required: true },
            { name: "occupation", label: "Present Occupation / Profession", type: 'input', required: true },
            { name: "hobbies", label: "Hobbies", type: 'input', required: true },
            { name: "joiningYear", label: "Year of Joining Round Table", type: 'input', required: true },
            { name: "leavingYear", label: "Year of Leaving Round Table", type: 'input', required: true },
            { name: "tableNo", label: "Round Table No.", type: 'input', required: true },
            { name: "yearsAsTabler", label: "No. of Years as Round Tabler", type: 'input', required: true },
            { name: "reasonForLeaving", label: "Reason for leaving Table (Retirement/Resignation/other)", type: 'multiline', required: true },
            { name: "positionsInRTI", label: "Positions held in RTI, if any", type: 'input', required: true },
            { name: "sqLeg", label: "Were you a Sq Leg / Hon Tr - If Yes, Name of Table", type: 'input', required: true },
            { name: "proposerName", label: "Name of the Proposer", type: 'input', required: true },
            { name: "spouseName", label: "Name of the Spouse", type: 'input', required: true },
            { name: "spouseDOB", label: "Date of Birth of Spouse", type: 'date', required: true },
            { name: "spouseLCMember", label: "Was she ever LC Member - If Yes, Name of Club", type: 'input', required: true },
            { name: "childrenNumber", label: "Number of Children", type: 'input', required: true },
            { name: "tShirtSize", label: "T-Shirt Size (S/L/XL/XXL/XXXL)", type: 'select', selectOptions: ['S', 'L', 'XL', 'XXL', 'XXXL'], required: true },
            { name: "declaration", label: "I do hereby declare that the information provided above is true  and nothing is false nor anthing has been concealed. I understand that any of above information found to be incorrect or misleading shell result in my immediate expulsion from the Assocoation & AOP", type: 'checkbox', required: true },
            { name: "paymentMethod", label: "Payment Method", type: 'select', selectOptions: ['NEFT/RTGS', 'Cheque', 'Demand Draft'], required: true },
            { name: "paymentDate", label: "Payment Date", type: 'date', required: true },
            { name: "paymentNumber", label: "Payment Transaction Number (UTR/Cheque/DD Number)", type: 'input', required: true },
        ];

        return (
            <Container>
                <h1>New Member Form</h1>
                <CustomForm fields={fields} onSubmit={(data) => this.console.log(data)}/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Info</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Payment Amount into Account 1</td>
                        <td>Rs. 5000 /-</td>
                    </tr>
                    <tr>
                        <td>Bank Account Information - Account 1</td>
                        <td>
                            In favour of: association of 41 clubs of India<br/>
                            Bank Name: Axis Bank Ltd<br/>
                            Account No: 914010042622856<br/>
                            Branch Name: Sterling Road, Chennai - 600034<br/>
                            IFS Code:UTIB0002018
                        </td>
                    </tr>
                    <tr>
                        <td>Payment Amount into Account 2</td>
                        <td>Rs. 12,500 /-</td>
                    </tr>
                    <tr>
                        <td>Bank Account Information - Account 2</td>
                        <td>
                            In favour of: 41 India<br/>
                            Bank Name: Axis Bank Ltd<br/>
                            Account No: 920020048319350<br/>
                            Branch Name: Sterling Road, Chennai - 600034<br/>
                            IFS Code:UTIB0002018
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        );
    }
}