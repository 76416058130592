import React from "react";
import {Helmet} from "react-helmet";

export default class Seo extends React.Component {
    render() {
        let title = this.props.title || '41 India';
        let description = this.props.description || '41 India is a leading national service organization comprising of retired members of Round Table India';
        let url = 'https://www.41clubsofindia.org' + this.props.slug;
        let image = 'https://www.41clubsofindia.org/logo.jpg'
        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description}/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>

                    <meta name="og:title" property="og:title" content={title} />
                    <meta name="of:url" property="og:url" content={url} />
                    <meta name="og:description" property="og:description" content={description} />
                    <meta name="og:image" property="og:image" content={image} />
                    <meta name="og:type" property="og:type" content="website" />

                    <link href={url} rel="canonical" />

                </Helmet>
            </div>
        );
    }
}