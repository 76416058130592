import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export default class PublicationViewer extends React.Component {
    render() {
        return (
            <Container>
                <h1>{this.props.obj.header}</h1>
                {this.props.obj.data.map((magazineType, idx1) => {
                    let result = <h2>{magazineType.title}</h2>
                    let magazines = magazineType.issues.map((issue, idx2) => {
                        return (
                            <Col sm={12} md={3} style={{marginTop: '1%', marginBottom: '1%', textAlign: 'center'}} key={idx1 + '-' + idx2}>
                                <a href={issue['link']} target='_blank'>
                                    {issue.image ? <img src={issue.image} style={{
                                        width: '100%',
                                        height: 'auto',
                                        aspectRatio: '1',
                                        objectFit: 'scale-down'
                                    }}/> : <></>}
                                    {issue.title}
                                </a>
                            </Col>
                        )
                    })

                    return (<Row>{result}<Row>{magazines}</Row></Row>)
                })}

            </Container>
        );
    }
}