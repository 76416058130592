import React, { Component } from 'react';
import {Form, Button, Col, Row, Alert} from 'react-bootstrap';
import './CustomForm.css';
import Loading from "./Loading"; // Ensure the path is correct

class CustomForm extends Component {
    constructor(props) {
        super(props);
        const { initialValues = {} } = props;
        this.state = {
            formData: initialValues,
            errors: {},
            file: null // Add a state property for file
        };
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        const { formData } = this.state;
        // Update for checkbox, use 'checked' property. Otherwise, use 'value'.
        formData[name] = type === 'checkbox' ? checked : value;
        this.setState({ formData });
    }

    handleChangeFile = (e) => {
        // Update the file state when a file is selected
        this.uploadFileToS3(e);
        // this.setState({ file: e.target.files[0] });
    }

    validateForm = () => {
        const { formData } = this.state;
        const errors = {};

        this.props.fields.forEach(field => {
            if (field.type.toLowerCase() === 'checkbox' && field.required !== false) {
                if (!formData[field.name]) {
                    errors[field.name] = 'This field is required';
                }
            } else {
                if (field.required !== false && !formData[field.name]) {
                    errors[field.name] = 'This field is required';
                }
                if (field.type.toLowerCase() === 'email' && formData[field.name] && !/\S+@\S+\.\S+/.test(formData[field.name])) {
                    errors[field.name] = 'Invalid email';
                }
                if (field.type.toLowerCase() === 'phone' && formData[field.name] && !/^[\d\s()+-]+$/.test(formData[field.name])) {
                    errors[field.name] = 'Invalid phone number';
                }
            }
        });

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    }


    handleSubmit = (e) => {
        if (this.state.submitted) {
            return;
        }
        e.preventDefault();
        if (this.validateForm()) {
            // Prepare data to submit, including the file
            const formDataToSubmit = {
                ...this.state.formData,
                file: this.state.file
            };
            this.setState({
                ...this.state,
                loading: true
            })
            this.props.onSubmit(formDataToSubmit);
        }
    }

    uploadFileToS3 = async (e) => {
        let file = e.target.files[0]
        let fileName = file.name
        let fileType = file.type;

        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        fileName = `${year}/${month}/${day}/${hours}${minutes}${seconds}-${fileName}`;

        // Call your API to get the pre-signed URL
        const response = await fetch('https://v9usf0lc3f.execute-api.ap-south-1.amazonaws.com/upload', {
            method: 'POST',
            body: JSON.stringify({
                fileName: fileName,
                fileType: fileType
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { url } = await response.json();


        // Use the pre-signed URL to upload the file
        const s3Response = await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': fileType
            }
        });

        if (s3Response.ok) {
            console.log('Upload successful');
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    [e.target.name]: 'https://41-public-dump.s3.ap-south-1.amazonaws.com/' + fileName
                }
            });
            // You can return or process the S3 object URL here if needed
        } else {
            console.error('Upload failed');
        }
    };


    handleWhatsApp = (phone) => {
        const phoneNumeric = phone.replace(/[^\d]/g, '');
        const url = `https://web.whatsapp.com/send?phone=${phoneNumeric}`;
        window.open(url, '_blank');
    }

    handleClear = () => {
        this.setState({ formData: {}, errors: {} });
    }

    render() {
        const { formData, errors } = this.state;

        return (
            <div className="custom-form-container">
                {(this.state.loading && !this.props.submitted) && <Loading />}
                {(this.props.submitted && !this.state.loading) && <Alert variant={'success'}>Your form has been submitted!</Alert> }
                <div className="custom-form-header"><img width={'50%'} src={'https://assets.41clubsofindia.org/logo.jpg'} /></div>
                <div className="custom-form-header">{this.props.title ? this.props.title : 'Form'}</div>
                <Form onSubmit={this.handleSubmit}>
                    {this.props.fields.map((field, index) => {
                        if (!field.name || !field.label || !field.type) return null;
                        const fieldType = field.type.toLowerCase();
                        let FieldComponent = Form.Control;

                        switch (fieldType) {
                            case 'select':
                            case 'multiselect':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            as="select"
                                            multiple={fieldType === 'multiselect'}
                                            name={field.name}
                                            value={formData[field.name] || (fieldType === 'multiselect' ? [] : '')}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        >
                                            <option value="">Select...</option>
                                            {field.selectOptions && field.selectOptions.map((option, idx) => (
                                                <option key={idx} value={option}>{option}</option>
                                            ))}
                                        </FieldComponent>
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'phone':
                                return (
                                    <Form.Group as={Row} key={index}>
                                        {/*<Col sm={10}>*/}
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type="number"
                                            name={field.name}
                                            value={formData[field.name] || ''}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                        {/*</Col>*/}
                                        {/*<Col sm={2}>*/}
                                        {/*    <Button variant="outline-primary" onClick={() => this.handleWhatsApp(formData[field.name])}>WhatsApp</Button>*/}
                                        {/*</Col>*/}
                                    </Form.Group>
                                );
                            case 'datetime':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type="datetime-local"
                                            name={field.name}
                                            value={formData[field.name] || new Date().toISOString().slice(0, 16)}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'datetime':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type="date"
                                            name={field.name}
                                            value={formData[field.name] || new Date().toISOString().slice(0, 16)}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'password':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type="password"
                                            name={field.name}
                                            value={formData[field.name] || ''}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'checkbox':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Check
                                            type="checkbox"
                                            name={field.name}
                                            checked={!!formData[field.name]} // ensure boolean
                                            onChange={this.handleChange}
                                            label={field.label}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'multiline':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label}{field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            as="textarea"
                                            rows={3}
                                            name={field.name}
                                            value={formData[field.name] || ''}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            case 'file':
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type="file"
                                            name={field.name}
                                            onChange={this.handleChangeFile}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                            default:
                                return (
                                    <Form.Group key={index}>
                                        <Form.Label>{field.label} {field.required !== false && <span className="text-danger">*</span>}</Form.Label>
                                        <FieldComponent
                                            type={fieldType}
                                            name={field.name}
                                            value={formData[field.name] || ''}
                                            onChange={this.handleChange}
                                            isInvalid={errors[field.name]}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
                                    </Form.Group>
                                );
                        }
                    })}
                    {(this.state.loading && !this.props.submitted) && <Loading />}
                    {(this.props.submitted && !this.state.loading) && <Alert variant={'success'}>Your form has been submitted!</Alert> }
                    <Button className="custom-form-button" type="submit" disabled={this.props.submitted}>Submit</Button>
                    <Button className="custom-form-button" variant="light" onClick={this.handleClear} disabled={this.props.submitted}>Clear</Button>
                    {(this.state.loading && !this.props.submitted) && <Loading />}
                    {(this.props.submitted && !this.state.loading) && <Alert variant={'success'}>Your form has been submitted!</Alert> }
                    {/*<Button variant="primary" type="submit">Submit</Button>*/}
                    {/*<Button variant="light" onClick={this.handleClear} className="ml-2">Clear</Button>*/}
                </Form>
            </div>

        );
    }
}

export default CustomForm;