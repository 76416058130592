import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default class Loading extends React.Component {
    render() {
        return (
            <div className={'text-center'}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
}