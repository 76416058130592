import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Seo from "../../Components/Seo";

export default class CancellationRefund extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Cancellation and Refund Policy | 41 India'}
                     description={'Read the Cancellation and Refund Policy of 41 India'}
                     slug={'/refunds'} />
                <Container>
                    <h1>Cancellation and Refund Policy</h1>
                    <Row>
                        <p>
                            41 India does not accept any cancellation or provide any refunds for payments made via this
                            website.
                        </p>

                        <p>
                            All payments made via this website are fees made towards administrative costs and cannot be
                            modified in any manner once processed.
                        </p>
                    </Row>
                </Container>
            </div>
        );
    }
}